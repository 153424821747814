import React from 'react';
import _ from 'lodash';
import i18next from 'i18next';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import {
  computeCellStyle,
  formatHeader,
  getExtraHeaderProps,
  getStripedColor,
  getTextValueForConditionHeader,
  isStartOrEndColumn,
} from '@/utilities/tableBuilderHelper.utilities';
import type {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColumnMovedEvent,
  DomLayoutType,
  EditableCallbackParams,
  GetRowIdFunc,
  GridApi,
  HeaderValueGetterParams,
  IAggFunc,
  ICellRendererParams,
  IHeaderParams,
  IRowNode,
  NewValueParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import type {
  BaseTableBuilderTextHeaderSimpleProps,
  ColumnOrRow,
  ColumnOrRowWithDefinitions,
  ComparatorValue,
  ConditionTableCapsule,
  ConditionTableColumnsAndRows,
  ConditionTableHeader,
  ConditionTableValue,
  ContentMeasurements,
  HiddenConditionHeaderProps,
  HiddenSimpleHeaderProps,
  TableBuilderConditionAgGridProps,
  TableBuilderSimpleAgGridProps,
  TableBuilderTextHeaderProps,
  TableBuilderTextHeaderSimpleProps,
  TableHeaderConditionProps,
} from '@/tableBuilder/tableBuilder.types';
import { TableHeaderCondition } from '@/tableBuilder/ag/TableHeaderCondition';
import { TableBuilderDataHeader } from '@/tableBuilder/tableComponents/TableBuilderDataHeader.atom';
import {
  AG_GRID_GROUPING_COL_ID,
  AG_GRID_METRIC_CLASSES,
  AG_GRID_ROOT_ROW_ID,
  AG_GRID_TEXT_CLASSES,
  AgGridAggregationFunction,
  AUTO_SIZE_MENU_ACTIONS,
  AUTOSIZED_COLUMN_DEFAULT_MAX_WIDTH,
  COLUMN_HEADER_ID,
  CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
  MAX_CONDITION_TABLE_CAPSULE_COLUMNS,
  MAX_HEADLESS_MODE_TABLE_ROWS,
  MAX_INTERACTIVE_CONTENT_TABLE_HEIGHT,
  MAX_INTERACTIVE_CONTENT_TABLE_ROWS,
  NULL_PLACEHOLDER,
  ROW_ID,
  SEEQ_ROW_INDEX,
  SIMPLE_TABLE_STRIPED_FIELD,
  SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
  TABLE_BUILDER_DEFAULT_CELL_WHITE_SPACE,
  TABLE_CELL_TOTAL_SIDE_PADDING,
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TextHeaderMenuAction,
} from '@/tableBuilder/tableBuilder.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { TableHeaderSimple, TableHeaderSimpleProps } from '@/tableBuilder/ag/TableHeaderSimple';
import { finishAgGridLoading, isPresentationWorkbookMode } from '@/utilities/utilities';
import { AnyProperty, HTMLElementOrNull } from '@/utilities.types';
import { getCellValue } from '@/tableBuilder/tableComponents/tableBuilderCell.utilities';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import { formatDuration, secondsToMillis } from '@/datetime/dateTime.utilities';
import { HeadlessJobFormat } from '@/services/headlessCapture.constants';
import { headlessCaptureMetadata } from '@/utilities/screenshot.utilities';
import type { AgGridAsyncProps } from '@/core/tableUtilities/tableUtilities.types';
import { MoreRowsMessage } from '@/tableBuilder/MoreRowsMessage';

const TransposedSimpleHeaderCellRenderer: React.FunctionComponent<
  ICellRendererParams<any, { headerComponentParams: TableHeaderSimpleProps }>
> = ({ value, registerRowDragger, api, column, node }) => {
  const headerComponentParams = value?.headerComponentParams;

  if (headerComponentParams) {
    return (
      <TableHeaderSimple
        {...headerComponentParams}
        column={column!}
        registerRowDragger={registerRowDragger}
        api={api}
        hideAutoSizeActions={(node.rowIndex ?? -1) > 0}
      />
    );
  }

  return <div></div>;
};

const getSimpleTextLeftMargin = (column: ColumnOrRowWithDefinitions) => {
  const { textAlign, sort, filter } = column;

  if (textAlign !== 'center') {
    return '';
  }

  if (sort?.level && filter) {
    return 'ml50';
  }
  if (sort?.level || filter) {
    return 'ml35';
  }

  return 'ml20';
};

const HiddenSimpleHeaderCellRenderer: React.FunctionComponent<HiddenSimpleHeaderProps> = ({
  valueFormatted,
  headerComponentParams,
  column,
  columnWithFormatting,
  api,
}) => {
  if (!headerComponentParams) {
    return <div></div>;
  }

  const headerMenu = (
    <span className="flexAlignCenter flexColumnContainer">
      <TableHeaderSimple
        {...headerComponentParams}
        column={column!}
        api={api}
        menuActions={[
          TextHeaderMenuAction.Filter,
          TextHeaderMenuAction.Sort,
          TextHeaderMenuAction.RowStyle,
          TextHeaderMenuAction.AutoSize,
          TextHeaderMenuAction.AutoSizeAll,
          TextHeaderMenuAction.Remove,
        ]}
        showMove={false}
        canEdit={false}
        showTextValue={false}
      />
    </span>
  );

  return (
    <div className="flexColumnContainer">
      {columnWithFormatting.textAlign === 'right' && headerMenu}
      <div className={`flexFill ${getSimpleTextLeftMargin(columnWithFormatting)}`}>{valueFormatted}</div>
      {columnWithFormatting.textAlign !== 'right' && headerMenu}
    </div>
  );
};

const ConditionHeaderRenderer: React.FunctionComponent<
  ICellRendererParams<any, string | { textHeaderProps: TableHeaderConditionProps }>
> = ({ value, registerRowDragger, column, api }) => {
  if (_.isString(value) || _.isNumber(value)) {
    return <TableBuilderDataHeader headerValue={value} />;
  }

  if (value?.textHeaderProps) {
    return (
      <TableHeaderCondition
        {...value.textHeaderProps}
        column={column!}
        registerRowDragger={registerRowDragger}
        api={api}
        menuActions={value.textHeaderProps.menuActions}
      />
    );
  }

  return <div></div>;
};

const getConditionTextLeftMargin = (textHeaderProps: TableHeaderConditionProps) => {
  const { columnTextAlign, sort, columnFilter } = textHeaderProps;

  if (columnTextAlign !== 'center') {
    return '';
  }

  if (sort?.sortLevel && columnFilter) {
    return 'ml55';
  }
  if (sort?.sortLevel) {
    return 'ml40';
  }
  if (columnFilter) {
    return 'ml35';
  }

  return 'ml20';
};

const HiddenConditionHeaderRenderer: React.FunctionComponent<HiddenConditionHeaderProps> = ({
  valueFormatted,
  textHeaderProps,
  column,
  api,
}) => {
  if (!textHeaderProps) {
    return <div></div>;
  }

  const filteredMenuActions = textHeaderProps.menuActions!.filter(
    (action: TextHeaderMenuAction) =>
      action !== TextHeaderMenuAction.GroupByColumn && action !== TextHeaderMenuAction.Rename,
  );

  const headerMenu = (
    <span className="flexAlignCenter flexColumnContainer">
      <TableHeaderCondition
        {...textHeaderProps}
        column={column!}
        api={api}
        menuActions={filteredMenuActions}
        showMove={false}
        canEdit={false}
        showTextValue={false}
      />
    </span>
  );

  return (
    <div className="flexColumnContainer">
      {textHeaderProps.columnTextAlign === 'right' && headerMenu}
      <div className={`flexFill ${getConditionTextLeftMargin(textHeaderProps)}`}>{valueFormatted}</div>
      {textHeaderProps.columnTextAlign !== 'right' && headerMenu}
    </div>
  );
};

/** CRAB-43260: Using this ensures that AG-Grid will be able to set the header height to zero when needed */
const EmptyHeader: React.FunctionComponent = () => <></>;

export const getSimpleTableHeaderComponentParams = (
  tableProps: TableBuilderSimpleAgGridProps,
  column: any,
  columnIndex: number,
  enableGrouping: boolean,
): TableBuilderTextHeaderSimpleProps => {
  const tableHeaderProps = _.pick(tableProps, [
    'autoGroupColumn',
    'columnToThresholds',
    'hasOnlyStringSeries',
    'hasOnlyStringMetrics',
    'hasNumericAndStringMetrics',
    'hasNumericAndStringSeries',
    'isPresentationMode',
    'canSort',
    'maxSortLevel',
    'sortByColumn',
    'isViewOnlyMode',
    'setHeaderText',
    'setColumnFilter',
    'distinctStringValueMap',
    'textFormatter',
    'moveColumn',
    'removeColumn',
    'columnIndex',
    'isTransposed',
    'darkMode',
    'headers',
    'displayRange',
    'timezone',
    'resetColumnWidth',
    'hideInteractiveContentActions',
  ]) as BaseTableBuilderTextHeaderSimpleProps;

  return {
    ...tableHeaderProps,
    simpleColumn: column,
    columnIndex,
    resetColumnWidth: tableProps.resetColumnWidth,
    menuActions: [],
    groupByColumn: enableGrouping ? tableProps.groupByColumn : undefined,
    setAggregationOnColumn: enableGrouping ? tableProps.setAggregationOnColumn : undefined,
    isInteractiveContent: !!tableProps.updateContentMeasurements,
  };
};

export function createSimpleColDefs(props: TableBuilderSimpleAgGridProps): ColDef[] {
  const {
    otherColumns,
    displayRange,
    isTransposed,
    simpleTableData,
    simpleColumns,
    isStriped,
    darkMode,
    updateContentMeasurements,
    autoGroupColumn,
    columnIdToAutoHeight,
  } = props;

  const canEdit = !props.isPresentationMode && !props.isViewOnlyMode;
  const isInteractiveContent = !_.isNil(updateContentMeasurements);
  const showUnitInSeparateColumn = _.some(
    simpleColumns,
    (column) => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key,
  );
  const valueFormatter = (
    params: ValueFormatterParams,
    field: string | number,
    column?: ColumnOrRowWithDefinitions,
  ): string => {
    const value = valueFormatterForRawData(params, field, column);
    const unit: string | undefined = getUnitForAgg(params, field, column);
    return getCellValue(
      value,
      !!unit || !showUnitInSeparateColumn || column?.style === 'percent',
      params.data?.[field]?.units ?? unit,
    );
  };

  return isTransposed
    ? Array.from<ColDef>(
        isTransposed && props.headers.type !== TableBuilderHeaderType.None
          ? [
              {
                colId: SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
                field: SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
                width: isInteractiveContent ? undefined : otherColumns[SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN]?.width,
                cellRenderer: TransposedSimpleHeaderCellRenderer,
                cellClass: 'transposed-header-cell',
                autoHeight: !!otherColumns[SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN]?.width || isInteractiveContent,
                valueFormatter: (params) => {
                  return getExtraHeaderProps(
                    params.data[SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN].headerComponentParams.simpleColumn,
                    props.headers,
                    props.displayRange,
                    props.timezone,
                  ).textValue;
                },
                cellStyle: (params: CellClassParams) => {
                  const textHeaderProps: TableBuilderTextHeaderSimpleProps = params.value.headerComponentParams;

                  return { backgroundColor: textHeaderProps?.simpleColumn.headerBackgroundColor ?? '' };
                },
              },
            ]
          : [],
      ).concat(
        simpleTableData.map((row, rowIndex) => {
          const colId = row.itemId;
          const width = otherColumns[colId]?.width;
          const autoHeightCheck = !!width || !!columnIdToAutoHeight[colId];
          return {
            chartDataType: 'series',
            colId: row.itemId,
            field: rowIndex.toString(),
            width: isInteractiveContent ? undefined : width,
            autoHeight: autoHeightCheck,
            autoHeaderHeight: autoHeightCheck,
            valueGetter: (params: ValueGetterParams) => valueGetterForRawData(params, rowIndex),
            valueFormatter: (params: ValueFormatterParams) =>
              valueFormatter(params, rowIndex, params.node?.rowIndex ? simpleColumns[params.node.rowIndex] : undefined),
            editable: (params: EditableCallbackParams) => {
              const columnIndex = params.node.rowIndex;

              return (
                canEdit && _.isNumber(columnIndex) && simpleColumns[columnIndex].type === TableBuilderColumnType.Text
              );
            },
            onCellValueChanged: (event: NewValueParams) => {
              if (!_.isNumber(event?.node?.rowIndex)) {
                return;
              }

              props.setCellText(
                simpleColumns[event!.node!.rowIndex]?.key,
                event.newValue,
                simpleTableData[rowIndex]?.itemId,
              );
            },
            onCellClicked: (params: CellClickedEvent) => {
              const columnIndex = params.rowIndex;
              if (columnIndex === null) {
                return;
              }

              const metricId = simpleTableData[rowIndex]?.cells[columnIndex]?.metricId;
              if (metricId) {
                props.displayMetricOnTrend(
                  metricId,
                  simpleTableData[rowIndex].itemId,
                  displayRange.start.valueOf(),
                  displayRange.end.valueOf(),
                  params.event,
                );
              }
            },
            cellStyle: (params: CellClassParams) => {
              const columnIndex = params.rowIndex;
              if (columnIndex === null) {
                return {};
              }

              const column = simpleColumns[columnIndex];
              const priorityColor = simpleTableData[rowIndex]?.cells[columnIndex]?.priorityColor;

              return computeCellStyle(
                column.backgroundColor,
                column.textColor,
                column.textStyle,
                column.textAlign,
                priorityColor,
                getStripedColor(isStriped, columnIndex, darkMode),
                darkMode,
                simpleColumns[columnIndex].type === TableBuilderColumnType.Text
                  ? undefined
                  : TABLE_BUILDER_DEFAULT_CELL_WHITE_SPACE,
              );
            },
            cellClass: (params: CellClassParams) => {
              const columnIndex = params.rowIndex;
              if (columnIndex === null) {
                return '';
              }

              const metricId = simpleTableData[rowIndex]?.cells[columnIndex]?.metricId;
              if (metricId) {
                return AG_GRID_METRIC_CLASSES;
              }

              if (simpleColumns[columnIndex].type === TableBuilderColumnType.Text) {
                return AG_GRID_TEXT_CLASSES;
              }

              return '';
            },
          };
        }),
      )
    : simpleColumns.map((column, columnIndex) => {
        const field = column.key;
        const headerComponentParams = getSimpleTableHeaderComponentParams(
          props,
          column,
          columnIndex,
          !isInteractiveContent,
        );

        return {
          chartDataType: 'series',
          colId: column.key,
          field,
          // This has to be null as that unsets the value, whereas undefined leaves the existing value in place
          sort: column?.sort?.direction ?? null,
          sortIndex: column?.sort?.level ? column.sort.level - 1 : null,
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const rowIndex1: number | undefined = nodeA?.data?.[SEEQ_ROW_INDEX];
            const rowIndex2: number | undefined = nodeB?.data?.[SEEQ_ROW_INDEX];

            let raw1: ComparatorValue;
            let raw2: ComparatorValue;
            if (!_.isNumber(rowIndex1) || !_.isNumber(rowIndex2)) {
              raw1 = nodeA?.aggData?.[column.key];
              raw2 = nodeB?.aggData?.[column.key];
            } else {
              const field = column.key;
              raw1 = nodeA.data[field]?.rawValue ?? null;
              raw2 = nodeB.data[field]?.rawValue ?? null;
            }

            return agGridSortComparator(raw1, raw2);
          },
          headerName:
            column.header ??
            (column.type === TableBuilderColumnType.Property ? column.key : i18next.t(column.shortTitle ?? '')),
          headerComponent: TableHeaderSimple,
          headerComponentParams,
          // This is surprisingly not exposed anywhere
          index: columnIndex,
          editable: canEdit && column.type === TableBuilderColumnType.Text,
          width: isInteractiveContent ? undefined : column.width,
          autoHeight: !!column.width || isInteractiveContent || !!columnIdToAutoHeight[column.key],
          autoHeaderHeight:
            !!column.width ||
            (isInteractiveContent && props.headers.type !== TableBuilderHeaderType.None) ||
            !!columnIdToAutoHeight[column.key],
          enableRowGroup: !!autoGroupColumn,
          rowGroup: !!column.grouping,
          rowGroupIndex: column.rowGroupOrder,
          // Needed because on load the column will be visible
          hide: !!column.grouping,
          aggFunc: column.aggregationFunction ?? null,
          headerValueGetter: headerValueGetterWithAggregation,
          valueGetter: (params: ValueGetterParams) => valueGetterForRawData(params, field),
          valueFormatter: (params: ValueFormatterParams) => valueFormatter(params, field, column),
          ...(isInteractiveContent && headerComponentParams.headers.type === TableBuilderHeaderType.None
            ? {}
            : {
                cellRenderer: (params: ICellRendererParams) => {
                  const noHeader = headerComponentParams.headers.type === TableBuilderHeaderType.None;
                  const displayedFirstRowIndex = params.api.getDisplayedRowAtIndex(0)?.data?.[SEEQ_ROW_INDEX];
                  if (params.data?.seeqRowIndex === displayedFirstRowIndex && noHeader) {
                    return (
                      <HiddenSimpleHeaderCellRenderer
                        valueFormatted={params.valueFormatted}
                        headerComponentParams={headerComponentParams as TableHeaderSimpleProps}
                        column={params.column}
                        columnWithFormatting={column}
                        api={params.api}
                      />
                    );
                  }

                  return params.valueFormatted;
                },
              }),
          onCellValueChanged: (event: NewValueParams) => {
            const rowIndex = event?.data?.[SEEQ_ROW_INDEX];
            if (!_.isNumber(rowIndex)) {
              return;
            }

            props.setCellText(column.key, event.newValue, simpleTableData[rowIndex]?.itemId);
          },
          onCellClicked: (params: CellClickedEvent) => {
            const rowIndex = params.data?.[SEEQ_ROW_INDEX];
            if (rowIndex === null) {
              return;
            }

            const metricId = simpleTableData[rowIndex]?.cells[columnIndex]?.metricId;
            if (metricId) {
              props.displayMetricOnTrend(
                metricId,
                simpleTableData[rowIndex].itemId,
                displayRange.start.valueOf(),
                displayRange.end.valueOf(),
                params.event,
              );
            }
          },
          cellStyle: (params: CellClassParams) => {
            let priorityColor: string | undefined;
            let fallbackColor: string | undefined;
            if (params?.node?.aggData?.[field] !== undefined) {
              priorityColor = undefined;
              fallbackColor = undefined;
            } else if (!params.data) {
              return {};
            } else {
              const rowIndex = params.data[SEEQ_ROW_INDEX];
              priorityColor = simpleTableData[rowIndex]?.cells[columnIndex]?.priorityColor;
              fallbackColor = params.data[SIMPLE_TABLE_STRIPED_FIELD];
            }

            return {
              ...computeCellStyle(
                column.backgroundColor,
                column.textColor,
                column.textStyle,
                column.textAlign,
                priorityColor,
                fallbackColor,
                darkMode,
                column.type === TableBuilderColumnType.Text ? undefined : TABLE_BUILDER_DEFAULT_CELL_WHITE_SPACE,
              ),
            };
          },
          cellClass: (params: CellClassParams) => {
            const rowIndex = params.data?.[SEEQ_ROW_INDEX];
            const metricId = simpleTableData[rowIndex]?.cells[columnIndex]?.metricId;
            if (metricId) {
              return AG_GRID_METRIC_CLASSES;
            }

            if (column.type === TableBuilderColumnType.Text) {
              return AG_GRID_TEXT_CLASSES;
            }

            return '';
          },
        };
      });
}

const isConditionTableCapsule = (potentialCapsule: unknown): potentialCapsule is ConditionTableCapsule => {
  return (
    potentialCapsule !== null &&
    typeof potentialCapsule === 'object' &&
    (_.has(potentialCapsule, 'startTime') || _.has(potentialCapsule, 'endTime'))
  );
};

export function createConditionColDefs(
  props: TableBuilderConditionAgGridProps,
  showCapsuleHeaders: boolean,
  hideHeaderRow = false,
  showConditionChartView = false,
): ColDef[] {
  const {
    columns: { columns, rows },
    headers,
    tableData,
    timezone,
    canEdit,
    isTransposed,
    otherColumns,
    updateContentMeasurements,
    autoGroupColumn,
    columnIdToAutoHeight,
  } = props;

  const defaultCategoryHeader = i18next.t('TABLE_BUILDER.CHART_VIEW_SETTINGS.HEADER_CATEGORY');
  const isInteractiveContent = !_.isNil(updateContentMeasurements);
  const showUnitInSeparateColumn = _.some(columns, (column) => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key);
  const valueFormatter = (
    params: ValueFormatterParams,
    field: string,
    units: string | undefined,
    column?: ColumnOrRowWithDefinitions,
  ): string => {
    const value = valueFormatterForRawData(params, field, column);
    const isAggregation = _.isNil(params?.data);
    const isCustomText =
      !isAggregation &&
      (!_.isObject(params?.data?.[field]) ||
        hasTextHeaderProps(params?.data?.[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN]));
    return isCustomText
      ? value
      : getConditionColumnValue(
          value?.toString(),
          params.node?.aggData && column?.aggregationFunction === 'count' ? undefined : units,
          showUnitInSeparateColumn,
        ) ?? '';
  };

  const transposedColumns = () => {
    const nameColumn = columns.find((column) => column.key === COLUMNS_AND_STATS.name.key);

    const headerDef: ColDef[] = showCapsuleHeaders
      ? [
          {
            colId: CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
            field: CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
            enableRowGroup: false,
            cellRenderer: ConditionHeaderRenderer,
            headerComponent: nameColumn ? TableHeaderCondition : undefined,
            headerComponentParams: nameColumn
              ? getConditionTextHeaderParamsForSpecialColumns(nameColumn, 0, props)
              : undefined,
            cellClass: 'transposed-header-cell',
            lockPosition: 'left',
            width: isInteractiveContent ? undefined : otherColumns[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN]?.width,
            autoHeight: !!otherColumns[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN]?.width || isInteractiveContent,
            autoHeaderHeight: !nameColumn
              ? undefined
              : !!otherColumns[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN]?.width || isInteractiveContent,
            headerValueGetter: (params) =>
              (params?.column as any)?.userProvidedColDef?.headerComponentParams?.textValue ?? '',
            valueFormatter: (params) =>
              hasTextHeaderProps(params.value) ? params.value.textHeaderProps.textValue : params.value,
            valueGetter: (params) => {
              const capsuleOrTextHeaderProps = params.data?.[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN];
              if (capsuleOrTextHeaderProps === undefined) {
                return '';
              }

              if (isConditionTableCapsule(capsuleOrTextHeaderProps)) {
                return formatHeader(
                  headers,
                  capsuleOrTextHeaderProps.property,
                  capsuleOrTextHeaderProps.startTime,
                  capsuleOrTextHeaderProps.endTime,
                  timezone,
                );
              }

              return capsuleOrTextHeaderProps;
            },
          },
        ]
      : [];

    if (showConditionChartView && headerDef.length === 1 && !headerDef[0].headerComponentParams?.textValue) {
      (headerDef[0].headerComponentParams ??= {}).textValue = '(Header)';
    }

    return headerDef.concat(
      tableData.headers.map((header, headerIndex) => {
        const rowWithGroupingInfo = props.conditionColumns.rows.find((row) => row.key === header.key);
        const propertyOrStatColumn = _.find(rows, {
          key: header.key,
          isPropertyOrStatColumn: true,
        });
        const potentialMetricColumn = _.find(
          rows.filter((row) => row.metricId),
          { key: header.key },
        );
        const autoHeightValue = columnIdToAutoHeight[header.key];
        const textHeaderProps =
          nameColumn || (hideHeaderRow && !isInteractiveContent)
            ? getConditionTextHeaderParams(header, nameColumn, propertyOrStatColumn, headerIndex, props, true)
            : undefined;
        const field = header.key;
        const width = propertyOrStatColumn?.width ?? potentialMetricColumn?.width;
        const sort = propertyOrStatColumn?.sort ?? potentialMetricColumn?.sort;
        const autoHeightCheck = !!width || isInteractiveContent || !!autoHeightValue;
        return {
          colId: header.key,
          chartDataType: textHeaderProps?.isStringColumn ?? false ? 'category' : 'series',
          field,
          // This has to be null as that unsets the value, whereas undefined leaves the existing value in place
          sort: sort?.direction ?? null,
          sortIndex: sort?.level ? sort.level - 1 : null,
          comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            let raw1: ComparatorValue;
            let raw2: ComparatorValue;
            if (nodeA?.aggData && nodeB?.aggData) {
              const agg1: any = nodeA?.aggData?.[header.key];
              const agg2: any = nodeB?.aggData?.[header.key];
              raw1 = agg1?.value === undefined ? agg1 : agg1.value;
              raw2 = agg2?.value === undefined ? agg2 : agg2.value;
            } else {
              const node1ColumnId = nodeA.data?.[COLUMN_HEADER_ID];
              const node2ColumnId = nodeB.data?.[COLUMN_HEADER_ID];
              if (node1ColumnId && node2ColumnId) {
                return 0;
              } else if (node1ColumnId) {
                // force the text node to be first no matter what
                raw1 = isDescending ? null : 1;
                raw2 = isDescending ? 1 : null;
              } else if (node2ColumnId) {
                // force the text node to be first no matter what
                raw1 = isDescending ? 1 : null;
                raw2 = isDescending ? null : 1;
              } else {
                const field = header.key;
                raw1 =
                  header.key === 'startTime'
                    ? nodeA?.data?.[field]?.startTime ?? -1
                    : nodeA?.data?.[field]?.rawValue ?? null;
                raw2 =
                  header.key === 'startTime'
                    ? nodeB?.data?.[field]?.startTime ?? -1
                    : nodeB?.data?.[field]?.rawValue ?? null;
              }
            }

            return agGridSortComparator(raw1, raw2);
          },
          width: isInteractiveContent ? undefined : width,
          autoHeight: autoHeightCheck,
          autoHeaderHeight: !nameColumn ? undefined : autoHeightCheck,
          headerName: header.name,
          headerComponent: textHeaderProps ? TableHeaderCondition : undefined,
          headerComponentParams: textHeaderProps,
          lockPosition: textHeaderProps?.showMove && canEdit ? undefined : 'left',
          headerValueGetter: headerValueGetterWithAggregation,
          valueGetter: conditionTableCellValueGetter(props, header, field),
          valueFormatter: (params: ValueFormatterParams) =>
            valueFormatter(params, field, header.units, propertyOrStatColumn),
          aggFunc: rowWithGroupingInfo?.aggregationFunction ?? null,
          enableRowGroup: !!autoGroupColumn,
          rowGroup: !!(rowWithGroupingInfo?.grouping ?? false),
          rowGroupIndex: rowWithGroupingInfo?.rowGroupOrder,
          ...(isInteractiveContent
            ? {}
            : {
                cellRenderer: (params: ICellRendererParams) => {
                  const displayedFirstRowIndex = params.api.getDisplayedRowAtIndex(0)?.data?.[SEEQ_ROW_INDEX];
                  if (params.data?.seeqRowIndex === displayedFirstRowIndex && hideHeaderRow && textHeaderProps) {
                    return (
                      <HiddenConditionHeaderRenderer
                        valueFormatted={params.valueFormatted}
                        textHeaderProps={textHeaderProps as TableHeaderConditionProps}
                        column={params.column}
                        api={params.api}
                      />
                    );
                  }
                  return params.valueFormatted;
                },
              }),
          // Needed because on load the column will be visible
          hide: !!rowWithGroupingInfo?.grouping,
          // We skip the first row and embed it in the headers due to bugs where certain text editing features don't
          // work if a cell conditionally uses a react component for rendering. This means that we need to increment
          // the row index by 1 when interacting with the [columns] prop. When grouping, the row index present in
          // the event/params from ag-grid comes from the actual index in the visual table, which can change when
          // groupings are opened or closed. So we embed the row index into the data and use that instead.
          editable: (params: EditableCallbackParams) => {
            const rowIndex = params.data[SEEQ_ROW_INDEX];
            if (!_.isNumber(rowIndex)) {
              return false;
            }

            const columnIndex = nameColumn ? rowIndex + 1 : rowIndex;

            return canEdit && columnIndex < columns.length && columns[columnIndex].type === TableBuilderColumnType.Text;
          },
          onCellClicked: (params: CellClickedEvent) => {
            const rowIndexMinus1 = params.data[SEEQ_ROW_INDEX];

            if (!_.isNumber(rowIndexMinus1)) {
              return;
            }

            const rowIndex = nameColumn ? rowIndexMinus1 + 1 : rowIndexMinus1;
            if (rowIndex < columns.length) {
              return;
            }

            const capsule = props.tableData.capsules[rowIndex - columns.length];
            if (!capsule) {
              return;
            }

            const value: ConditionTableValue = capsule.values[headerIndex];
            if (value?.formulaItemId) {
              props.displayMetricOnTrend(
                value.formulaItemId,
                value.itemId!,
                capsule.startTime,
                capsule.endTime,
                params.event as any,
              );
            }
          },
          onCellValueChanged: (event: NewValueParams) => {
            const rowIndexMinus1 = event?.data?.[SEEQ_ROW_INDEX];

            if (!_.isNumber(rowIndexMinus1)) {
              return;
            }

            const columnIndex = nameColumn ? rowIndexMinus1 + 1 : rowIndexMinus1;
            props.setCellText(columns[columnIndex!].key, event.newValue, header.key);
          },
          cellStyle: (params: CellClassParams) => {
            if (params.node?.aggData?.[header.key]) {
              const styleColumn = propertyOrStatColumn ?? potentialMetricColumn;
              return computeCellStyle(
                styleColumn?.backgroundColor,
                styleColumn?.textColor,
                styleColumn?.textStyle,
                styleColumn?.textAlign,
                undefined,
                undefined,
                props.darkMode,
                TABLE_BUILDER_DEFAULT_CELL_WHITE_SPACE,
              );
            }

            const rowIndexMinus1 = params?.data?.[SEEQ_ROW_INDEX];

            if (!_.isNumber(rowIndexMinus1)) {
              return;
            }

            const rowIndex = nameColumn ? rowIndexMinus1 + 1 : rowIndexMinus1;

            const maybeStripedColor = getStripedColor(props.isStriped, params.rowIndex, !!props.darkMode);
            // These are the custom text rows
            if (rowIndex < columns.length) {
              const column = columns[rowIndex];

              return computeCellStyle(
                column.backgroundColor,
                column.textColor,
                column.textStyle,
                column.textAlign,
                undefined,
                maybeStripedColor,
                props.darkMode,
                columns[rowIndex].type === TableBuilderColumnType.Text
                  ? undefined
                  : TABLE_BUILDER_DEFAULT_CELL_WHITE_SPACE,
              );
            }

            const capsule = props.tableData.capsules[rowIndex - columns.length];
            if (!capsule) {
              return {};
            }

            const value: ConditionTableValue = capsule.values[headerIndex];
            const styleColumn = propertyOrStatColumn ?? potentialMetricColumn;

            return computeCellStyle(
              styleColumn?.backgroundColor,
              styleColumn?.textColor,
              styleColumn?.textStyle,
              styleColumn?.textAlign,
              value.priorityColor === '#ffffff' ? maybeStripedColor : value.priorityColor,
              maybeStripedColor,
              props.darkMode,
              TABLE_BUILDER_DEFAULT_CELL_WHITE_SPACE,
            );
          },
          cellClass: (params: CellClassParams) => {
            const rowIndexMinus1 = params?.data?.[SEEQ_ROW_INDEX];

            if (!_.isNumber(rowIndexMinus1)) {
              return;
            }

            const rowIndex = nameColumn ? rowIndexMinus1 + 1 : rowIndexMinus1;
            if (rowIndex < columns.length) {
              if (columns[rowIndex].type === TableBuilderColumnType.Text) {
                return AG_GRID_TEXT_CLASSES;
              }

              return '';
            }

            const capsule = props.tableData.capsules[rowIndex - columns.length];
            if (!capsule) {
              return '';
            }

            const value: ConditionTableValue = capsule.values[headerIndex];
            if (value?.formulaItemId) {
              return AG_GRID_METRIC_CLASSES;
            }

            return '';
          },
        };
      }),
    );
  };

  const regularColumns = () => {
    return columns
      .map<ColDef>((column, columnIndex) => {
        const headerComponentParams = getConditionTextHeaderParamsForSpecialColumns(column, columnIndex, props);
        const field = getConditionColumnFieldName(columnIndex);

        return {
          chartDataType: 'category',
          colId: column.key,
          enableRowGroup: false,
          field,
          headerName: headerComponentParams?.textValue || defaultCategoryHeader,
          headerComponent: hideHeaderRow ? EmptyHeader : TableHeaderCondition,
          headerComponentParams,
          cellRenderer: column.key === COLUMNS_AND_STATS.name.key ? ConditionHeaderRenderer : undefined,
          lockPosition: columnIndex === 0 ? 'left' : undefined,
          index: columnIndex,
          editable: canEdit && column.type === TableBuilderColumnType.Text,
          width: isInteractiveContent ? undefined : column.width,
          minWidth: isInteractiveContent ? 100 : undefined,
          autoHeight: !!column.width || isInteractiveContent,
          autoHeaderHeight: !!column.width || isInteractiveContent,
          valueFormatter: (params) =>
            hasTextHeaderProps(params.value) ? params.value.textHeaderProps.textValue : params.value,
          valueGetter: conditionTableCellValueGetter(props, column.header, field, showConditionChartView),
          onCellValueChanged: (event: NewValueParams) => {
            if (!_.isNumber(event?.node?.rowIndex)) {
              return;
            }

            props.setCellText(column.key, event.newValue, props.tableData.headers[event!.node!.rowIndex]?.key);
          },
          cellStyle: (params: CellClassParams) => {
            if (!_.isNumber(params?.rowIndex)) {
              return;
            }

            if (typeof params.value === 'object') {
              const textHeaderProps: TableBuilderTextHeaderProps = params.value.textHeaderProps;

              return { backgroundColor: textHeaderProps?.headerBackgroundColor ?? '' };
            }

            const maybeStripedColor = getStripedColor(props.isStriped, params.rowIndex, !!props.darkMode);

            return computeCellStyle(
              column.backgroundColor,
              column.textColor,
              column.textStyle,
              column.textAlign,
              undefined,
              maybeStripedColor,
              props.darkMode,
              column.type === TableBuilderColumnType.Text ? undefined : TABLE_BUILDER_DEFAULT_CELL_WHITE_SPACE,
            ) as Record<string, any>;
          },
          cellClass: () => {
            if (column.type === TableBuilderColumnType.Text) {
              return AG_GRID_TEXT_CLASSES;
            }
          },
        };
      })
      .concat(
        // Limit the number of capsule columns for performance reasons
        tableData.capsules.slice(0, MAX_CONDITION_TABLE_CAPSULE_COLUMNS).map((capsule, index) => {
          const columnIndex = columns.length + index;
          const colId = capsule.id;

          const width = otherColumns[colId]?.width;
          const field = getConditionCapsuleFieldName(index);
          const autoHeightCheck = !!width || isInteractiveContent || !!columnIdToAutoHeight[colId];
          return {
            colId,
            enableRowGroup: false,
            field,
            headerComponent: (props: IHeaderParams) => {
              return <TableBuilderDataHeader headerValue={props.displayName} key={columnIndex} />;
            },
            lockPosition: 'right',
            width: isInteractiveContent ? undefined : width,
            autoHeight: autoHeightCheck,
            autoHeaderHeight: autoHeightCheck,
            headerValueGetter: () =>
              formatHeader(headers, capsule.property, capsule.startTime, capsule.endTime, timezone),
            valueGetter: (params: ValueGetterParams) => {
              const header = props.tableData.headers[params.node?.rowIndex ?? -1];
              if (!isStartOrEndColumn(header)) {
                return valueGetterForRawData(params, field);
              }

              const capsule = params.data[field];
              if (!capsule) {
                return '';
              }

              return formatHeader(
                {
                  ...headers,
                  type:
                    header.key === COLUMNS_AND_STATS.startTime.key
                      ? TableBuilderHeaderType.Start
                      : TableBuilderHeaderType.End,
                },
                capsule.property,
                capsule.startTime,
                capsule.endTime,
                timezone,
              );
            },
            valueFormatter: (params: ValueFormatterParams) =>
              valueFormatter(
                params,
                field,
                _.isNumber(params?.node?.rowIndex) ? props.tableData.headers[params!.node!.rowIndex]?.units : undefined,
              ),
            onCellClicked: (params: CellClickedEvent) => {
              const rowIndex = params.rowIndex;
              if (rowIndex === null) {
                return;
              }

              const header = props.tableData.headers[rowIndex];
              if (isStartOrEndColumn(header)) {
                return;
              }

              const value: ConditionTableValue = capsule.values[rowIndex];
              const formulaItemId = value?.formulaItemId;
              if (formulaItemId) {
                props.displayMetricOnTrend(
                  formulaItemId,
                  value.itemId!,
                  capsule.startTime,
                  capsule.endTime,
                  params.event as any,
                );
              }
            },
            cellStyle: (params: CellClassParams) => {
              const rowIndex = params.rowIndex;
              if (rowIndex === null) {
                return;
              }

              const maybeStripedColor = getStripedColor(props.isStriped, params.rowIndex, !!props.darkMode);
              const header = props.tableData.headers[rowIndex];
              const row = props.conditionColumns.rows.find((row) => row.key === header.key);

              if (!row) {
                return {};
              }

              const value: ConditionTableValue | undefined = capsule.values[rowIndex];
              if (!value) {
                return {};
              }

              return computeCellStyle(
                row.backgroundColor,
                row.textColor,
                row.textStyle,
                row.textAlign,
                value.priorityColor === '#ffffff' ? maybeStripedColor : value.priorityColor,
                maybeStripedColor,
                props.darkMode,
                row.type === TableBuilderColumnType.Text ? undefined : TABLE_BUILDER_DEFAULT_CELL_WHITE_SPACE,
              );
            },
            cellClass: (params: CellClassParams) => {
              const rowIndex = params.rowIndex;
              if (rowIndex === null) {
                return;
              }

              const value: ConditionTableValue | undefined = capsule.values[rowIndex];
              if (value?.formulaItemId) {
                return AG_GRID_METRIC_CLASSES;
              }

              return '';
            },
          };
        }),
      );
  };

  return isTransposed ? transposedColumns() : regularColumns();
}

/**
 * Searches through all aggregated rows for their units field. If they are all the same, return that unit. Else,
 * return undefined.
 * @param params - The ag-grid params
 * @param field - The name of the field
 * @param column - the column corresponding to the field
 */
const getUnitForAgg = (
  params: ValueFormatterParams,
  field: string | number,
  column: ColumnOrRowWithDefinitions | undefined,
): string | undefined => {
  let unit: string | undefined;
  if (params?.node?.aggData?.[field] !== undefined && column?.aggregationFunction !== 'count') {
    unit = params.node.allLeafChildren?.reduce((acc, node) => {
      if (acc === undefined) {
        return undefined;
      }
      const currentUnits = node.data?.[field]?.units;
      if (acc === '' || (acc === currentUnits && acc !== undefined)) {
        return currentUnits;
      }

      return undefined;
    }, '');
  }

  return unit;
};

/**
 * Formats a cell that uses the `valueGetterForRawData`. It checks if the data is a computed aggregation by
 * ag-grid, in which case it formats it. Otherwise it returns the formatted value from the store.
 *
 * @param params - The ag-grid params
 * @param field - The name of the field
 * @param column - The column definition
 */
export const valueFormatterForRawData = (
  params: ValueFormatterParams,
  field: string | number,
  column?: ColumnOrRowWithDefinitions,
): string => {
  const hasFormattedValue =
    (!_.isNil(params.data?.[field]?.rawValue) && params.data?.[field]?.rawValue === params.value) ||
    params.data?.[field]?.value === NULL_PLACEHOLDER;
  let value = params.value;
  const potentiallyCustomStringCell = _.isNil(value) && _.isString(params.data?.[field]);
  if (potentiallyCustomStringCell) {
    value = params.data?.[field];
  } else if (hasFormattedValue) {
    value = params.data[field].value;
  } else {
    // Value is not formatted or a custom string cell, so it is from an aggregation
    value = _.isObject(value) ? value.value : value;
    if (
      _.includes(
        [COLUMNS_AND_STATS['statistics.totalDuration'].key, SeeqNames.CapsuleProperties.Duration],
        column?.key,
      ) &&
      _.isNumber(value) &&
      column?.aggregationFunction !== 'count'
    ) {
      value = formatDuration(secondsToMillis(value));
    } else if (_.isNumber(value)) {
      value = formatNumber(value);
    }
  }

  return value;
};

/**
 * Returns the header name with the aggregation prefix, if the column has one.
 *
 * @param params - The header params
 * @return The header name with the aggregation prefix
 */
const headerValueGetterWithAggregation = (params: HeaderValueGetterParams): string => {
  const colDef = params.colDef as ColDef;
  const headerName = colDef.headerName;
  const aggFunc = colDef?.aggFunc as AgGridAggregationFunction;
  if (!aggFunc) {
    return headerName ?? '';
  }
  return !headerName ? '' : getAggregationPrefix(aggFunc) + headerName;
};

export const getAggregationPrefix = (aggregationFunction: AgGridAggregationFunction): string => {
  const isAggregation = aggregationFunction && aggregationFunction !== 'none';
  return isAggregation ? `${i18next.t(`TABLE_BUILDER.AGGREGATION.${aggregationFunction.toUpperCase()}`)}: ` : '';
};

/**
 * Given a cell that has both a formatted value and the raw value that is the source of that formatted value this
 * returns the raw value so aggregations can be done on it.
 *
 * @param params - The ag-grid params
 * @param field - The name of the field
 */
const valueGetterForRawData = (params: ValueGetterParams, field: string | number, showConditionChart?: boolean) => {
  const value = params.data?.[field]?.rawValue ?? params.data?.[field]?.value ?? params.data?.[field];

  return hasTextHeaderProps(value) && showConditionChart ? value.textHeaderProps.textValue : value;
};

function conditionTableCellValueGetter(
  props: TableBuilderConditionAgGridProps,
  header: ConditionTableHeader,
  field: string,
  showConditionChart?: boolean,
) {
  const { headers, timezone } = props;

  return isStartOrEndColumn(header)
    ? (params: ValueGetterParams) => {
        const capsule = params.data?.[field];
        if (!capsule) {
          return '';
        } else if (!_.isObject(capsule)) {
          return capsule;
        }

        return formatHeader(
          {
            ...headers,
            type:
              header.key === COLUMNS_AND_STATS.startTime.key
                ? TableBuilderHeaderType.Start
                : TableBuilderHeaderType.End,
          },
          capsule.property,
          capsule.startTime,
          capsule.endTime,
          timezone,
        );
      }
    : (params: ValueGetterParams) => valueGetterForRawData(params, field, showConditionChart);
}

/**
 * Generates the props that are used by {@link TableBuilderTextHeader} to create the menu for the name, UOM, and
 * custom text columns. Allows both the cell and the row to be formatted since the "row" also acts as header cells.
 */
export function getConditionTextHeaderParamsForSpecialColumns(
  column: ColumnOrRowWithDefinitions,
  columnIndex: number,
  props: TableBuilderConditionAgGridProps,
): TableBuilderTextHeaderProps | undefined {
  if (!column) {
    return undefined;
  }

  const isInteractiveContent = !!props.updateContentMeasurements;
  const headerName = column.header ?? (column.key === COLUMNS_AND_STATS.name.key ? '' : i18next.t(column.shortTitle!));

  return {
    textValue: headerName,
    isInput: true,
    columnIndex,
    columnKey: column.key,
    onTextChange: (value) => props.setCellText(column.key, value),
    columnBackgroundColor: column.backgroundColor,
    columnTextAlign: column.textAlign,
    columnTextColor: column.textColor,
    columnTextStyle: column.textStyle,
    headerBackgroundColor: column.headerBackgroundColor,
    headerTextAlign: column.headerTextAlign,
    headerTextColor: column.headerTextColor,
    headerTextStyle: column.headerTextStyle,
    canEdit: props.canEdit,
    isTransposed: props.isTransposed,
    menuActions: (props.canEdit
      ? [
          TextHeaderMenuAction.HeaderStyle,
          TextHeaderMenuAction.RowStyle,
          TextHeaderMenuAction.Rename,
          TextHeaderMenuAction.Remove,
        ]
      : []
    ).concat(!props.isPresentationMode && !props.isTransposed ? AUTO_SIZE_MENU_ACTIONS : []),
    textFormatter: props.textFormatter,
    sort: {
      canSort: props.canSort,
      maxSortLevel: props.maxSortLevel,
      sortDirection: column.sort?.direction,
      sortLevel: column.sort?.level,
      sortByColumn: props.sortByColumn,
    },
    showMove: column.key !== COLUMNS_AND_STATS.name.key,
    moveColumn: props.moveColumn,
    removeColumn: props.removeColumn,
    fetchStringColumnValues: props.fetchStringColumnValues,
    darkMode: !!props.darkMode,
    resetColumnWidth: props.resetColumnWidth,
    isFilterDisabled: isInteractiveContent,
    isInteractiveContent,
    hideInteractiveContentActions: !!props.hideInteractiveContentActions,
  };
}

/**
 * Generates the props that are used by {@link TableBuilderTextHeader} to create the menu for the property,
 * statistic, and metric columns. Note that this does not allow the header cell formatting as that is handled by
 * as a "row", nor does it allow for background/foreground color to be changed since colors are best used in the data
 * to indicate something about the data which is the purpose of a metric.
 */
export function getConditionTextHeaderParams(
  header: ConditionTableHeader,
  nameColumn: ColumnOrRow | undefined,
  statOrPropertyColumn: ColumnOrRowWithDefinitions | undefined,
  headerIndex: number,
  props: TableBuilderConditionAgGridProps,
  enableGrouping: boolean,
): TableBuilderTextHeaderProps {
  const potentialMetricColumn = _.find(props.columns.rows, (column) => column.key === header.key && column.metricId);
  const columnData = _.find(props.columns.rows, (column) => column.key === header.key);
  const isMetricColumn = !!potentialMetricColumn;
  const isInteractiveContent = !!props.updateContentMeasurements;
  const styleColumn = isMetricColumn ? potentialMetricColumn : statOrPropertyColumn;
  let menuActions: TextHeaderMenuAction[] = [];
  if (!props.isPresentationMode || isInteractiveContent) {
    menuActions = menuActions
      .concat(TextHeaderMenuAction.Sort)
      .concat(!isStartOrEndColumn(statOrPropertyColumn) ? TextHeaderMenuAction.Filter : [])
      .concat(props.canEdit ? TextHeaderMenuAction.ColumnStyle : [])
      .concat(statOrPropertyColumn && props.canEdit ? [TextHeaderMenuAction.Rename, TextHeaderMenuAction.Remove] : [])
      .concat(!props.isPresentationMode && props.isTransposed ? AUTO_SIZE_MENU_ACTIONS : [])
      .concat(!props.isContent && props.isTransposed ? [TextHeaderMenuAction.GroupByColumn] : [])
      .concat(
        !props.isContent && props.isTransposed && !!props.autoGroupColumn
          ? [TextHeaderMenuAction.AggregateByColumn]
          : [],
      );
  }

  return {
    textValue: statOrPropertyColumn
      ? statOrPropertyColumn.header ?? header.name
      : getTextValueForConditionHeader(header, nameColumn),
    isInput: true,
    columnIndex: headerIndex,
    columnKey: statOrPropertyColumn?.key ?? header.key,
    onTextChange: (value: any) => statOrPropertyColumn && props.setHeaderText(statOrPropertyColumn.key, value),
    headerBackgroundColor: nameColumn && nameColumn.backgroundColor,
    headerTextAlign: nameColumn && nameColumn.textAlign,
    headerTextColor: nameColumn && nameColumn.textColor,
    headerTextStyle: nameColumn && nameColumn.textStyle,
    columnBackgroundColor: styleColumn?.backgroundColor,
    columnTextAlign: styleColumn?.textAlign,
    columnTextColor: styleColumn?.textColor,
    columnTextStyle: styleColumn?.textStyle,
    canEdit: !!(statOrPropertyColumn || isMetricColumn) && props.canEdit,
    isTransposed: props.isTransposed,
    isStringColumn: header.isStringColumn,
    isDurationColumn: statOrPropertyColumn?.key === SeeqNames.Properties.Duration,
    isFilterDisabled: isInteractiveContent ? isStartOrEndColumn(statOrPropertyColumn) : props.isPresentationMode,
    isInteractiveContent,
    distinctStringValues: props.distinctStringValueMap[statOrPropertyColumn?.key ?? header.key],
    thresholds: props.columnToThresholds[header.key],
    fetchStringColumnValues: props.fetchStringColumnValues,
    menuActions,
    textFormatter: props.textFormatter,
    aggregationFunction: columnData?.aggregationFunction,
    setAggregationOnColumn: props.setAggregationOnColumn,
    sort: {
      canSort: props.canSort,
      maxSortLevel: props.maxSortLevel,
      sortDirection: statOrPropertyColumn
        ? statOrPropertyColumn?.sort?.direction
        : potentialMetricColumn?.sort?.direction,
      sortLevel: statOrPropertyColumn ? statOrPropertyColumn?.sort?.level : potentialMetricColumn?.sort?.level,
      sortByColumn: props.sortByColumn,
    },
    setColumnFilter: props.setColumnFilter,
    columnFilter: statOrPropertyColumn ? statOrPropertyColumn.filter : potentialMetricColumn?.filter,
    removeColumn: statOrPropertyColumn || isMetricColumn ? props.removeColumn : undefined,
    moveColumn: statOrPropertyColumn || isMetricColumn ? props.moveColumn : undefined,
    showMove: true,
    darkMode: !!props.darkMode,
    isMetric: isMetricColumn,
    resetColumnWidth: props.resetColumnWidth,
    groupByColumn: enableGrouping ? props.groupByColumn : undefined,
    hideInteractiveContentActions: !!props.hideInteractiveContentActions,
  };
}

export function addScreenshotSetup(isChartView: boolean) {
  if (!headlessRenderMode() || isChartView) {
    return;
  }

  // Add the screenshotSizeToContent class to the root wrapper because the parent div does not have the correct
  // width and makes screenshots too wide
  const rootContainer = document.querySelector('.ag-root-wrapper');
  rootContainer?.classList.add('screenshotSizeToContent');
}

export function getConditionColumnFieldName(index: number): string {
  return `column${index}`;
}

export function getConditionCapsuleFieldName(index: number): string {
  return `capsule${index}`;
}

/**
 * Restricts the maximum width of autosized columns to {@link AUTOSIZED_COLUMN_DEFAULT_MAX_WIDTH}
 * to ensure that auto-height is enabled for these columns - since auto-height is only enabled for columns that
 * have an explicit width.
 *
 * Also ensures that columns are not too wide
 *
 * @param agGridApi - The ag-Grid API instance for the table.
 * @param columnsWithoutExplicitWidth - An array of column IDs that have not had an explicit width set on
 * them and which will be checked to see if they exceed the maximum width.
 */
function restrictAutosizedColumnsDefaultWidth(
  agGridApi: GridApi | undefined,
  columnsWithoutExplicitWidth: string[],
): void {
  const columnState = agGridApi?.getColumnState();
  const columnsWithoutExplicitWidthSet = new Set(columnsWithoutExplicitWidth);

  columnState?.forEach((state) => {
    if (state.width! > AUTOSIZED_COLUMN_DEFAULT_MAX_WIDTH && columnsWithoutExplicitWidthSet.has(state.colId)) {
      agGridApi?.setColumnWidth(state.colId, AUTOSIZED_COLUMN_DEFAULT_MAX_WIDTH);
    }
  });
}

export const calculateWidth = (agGridApi: GridApi | undefined) => {
  if (agGridApi && agGridApi.getAllDisplayedColumns()) {
    return agGridApi
      .getAllDisplayedColumns()
      .map((s) => s.getActualWidth())
      .reduce((totalWidth, currentWidth) => totalWidth! + currentWidth!, 0)!;
  }
};

/**
 * Size the columns such that the ag-grid table fits the width of the parent element. Makes the content slightly
 * narrower when rendering a PDF, to account for issue where right side of table would be cut off (CRAB-41997). Fits
 * the gold border snugly around the content when content is in a Topic.
 *
 * @param columnDefs
 * @param isInteractiveContent
 * @param agGrid
 * @param agGridWrapperRef
 * @param afterAutoSize
 * @param ignoreHeaders
 */
export const autoSizeColumns = (
  columnDefs: ColDef[],
  isInteractiveContent: boolean,
  agGridApi: GridApi | undefined,
  agGridWrapperRef: HTMLElementOrNull,
  afterAutoSize: (newWidthIfInteractive: number | undefined) => void,
  ignoreHeaders = false,
) => {
  if (!agGridApi) {
    return;
  }

  let closestTableCell: HTMLTableCellElement | null | undefined;
  let maybeNewWidth: number | undefined;
  let boundingWidth: number | undefined;
  const contentWrapperComponent = agGridWrapperRef?.closest('.seeqContentWrapper') as HTMLDivElement | undefined;

  if (isInteractiveContent && contentWrapperComponent) {
    // An interactive table is always within a td or a p, so use that parent to set the max-width
    closestTableCell = agGridWrapperRef?.closest('td');
    const widthBoundingParent: HTMLElement | null | undefined = closestTableCell ?? agGridWrapperRef?.closest('p');
    if (widthBoundingParent) {
      boundingWidth = widthBoundingParent.clientWidth - (!closestTableCell ? 0 : TABLE_CELL_TOTAL_SIDE_PADDING);
      contentWrapperComponent.style.setProperty('max-width', `${boundingWidth}px`);
    }
  }

  try {
    if (isInteractiveContent && closestTableCell) {
      agGridApi.sizeColumnsToFit();
    } else {
      const columnsToAutoSize = columnDefs
        .filter((columnDef) => !_.isNil(columnDef.colId) && _.isNil(columnDef.width))
        .map((columnDef) => columnDef.colId!);

      agGridApi.autoSizeColumns(columnsToAutoSize, ignoreHeaders);
      restrictAutosizedColumnsDefaultWidth(agGridApi, columnsToAutoSize);
    }

    if (isInteractiveContent) {
      maybeNewWidth = calculateWidth(agGridApi);
      if (
        maybeNewWidth &&
        contentWrapperComponent &&
        boundingWidth &&
        maybeNewWidth > contentWrapperComponent.clientWidth
      ) {
        // Autosizing all the columns would make them too big for the space the table is currently in, so instead,
        // make the table fill it's current space.
        agGridApi.sizeColumnsToFit(boundingWidth);
        maybeNewWidth = calculateWidth(agGridApi);
      }
    }
  } finally {
    afterAutoSize(maybeNewWidth);
  }
};

export const onColumnMoved = (event: ColumnMovedEvent, moveColumn: (key: string, newKey: string) => void) => {
  if (!event.column || !_.isNumber(event.toIndex) || !event.finished) {
    return;
  }

  const source = event.column.getColDef().colId;
  const autoGroupColumn = event.api
    .getAllDisplayedColumns()
    .find((column) => column.getColId() === AG_GRID_GROUPING_COL_ID);
  const target = event.api.getColumns()?.[
    // If we're grouping, all column indices have to be subtracted by one
    autoGroupColumn ? event.toIndex - 1 : event.toIndex
  ]?.getColDef()?.colId;
  if (!source || !target) {
    return;
  }

  moveColumn(source, target);
};

const onRowDragEndInGrid = (rowNode: IRowNode, columns: any[], moveColumn: (key: string, newKey: string) => void) => {
  if (_.isNil(rowNode.rowIndex)) {
    return;
  }

  const source = rowNode.data[COLUMN_HEADER_ID];
  // If there is no target, set it to the source, so we snap back into place, otherwise agGrid will not put it back
  // in its column because no props changed
  const target = columns[rowNode.rowIndex]?.key ?? source;

  if (!source || !target) {
    return;
  }

  moveColumn(source, target);
};

export const onRowDragEnd = (
  rowNode: IRowNode,
  columns: any[],
  moveColumn: (key: string, newKey: string) => void,
  /** This contains the row-node dragged outside the grid or `undefined` if the dragged row was dropped within the grid */
  rowNodeOutsideGridRef: React.MutableRefObject<IRowNode | undefined>,
) => {
  onRowDragEndInGrid(rowNode, columns, moveColumn);
  rowNodeOutsideGridRef.current = undefined;
};

export const getConditionTableDragColumns = (
  { columns, rows }: ConditionTableColumnsAndRows,
  isTransposed: boolean,
) => {
  if (isTransposed) {
    return columns.slice(1);
  }
  return rows;
};

export function getDomLayout(isInteractiveContent: boolean, rowCount: number): DomLayoutType {
  if (!headlessRenderMode() && rowCount > MAX_INTERACTIVE_CONTENT_TABLE_ROWS) {
    return 'normal';
  }

  // ag-grid table's auto-sizing does not work in 'print' mode CRAB-41997
  if (isPresentationWorkbookMode() && headlessCaptureMetadata().jobFormat !== HeadlessJobFormat.PDF) {
    return 'print';
  }

  if (isInteractiveContent) {
    return 'autoHeight';
  }

  return 'normal';
}

const hasTextHeaderProps = (value: unknown): value is { textHeaderProps: TableBuilderTextHeaderProps } => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'textHeaderProps' in value &&
    typeof value.textHeaderProps === 'object' &&
    value.textHeaderProps !== null &&
    'columnKey' in value.textHeaderProps &&
    'textValue' in value.textHeaderProps
  );
};

export const getConditionColumnValue = (
  value: string | undefined,
  units: string | undefined,
  showUnitInASeparateColumn: boolean,
) => (!units || showUnitInASeparateColumn || value === '-' || !value ? value : `${value} ${units}`);

export const getRowId: GetRowIdFunc = (params) => {
  return params.data[ROW_ID];
};

/**
 * Gets the full path of a grouped row node, which is made of the starting node's and ancestors' keys (the value in
 * the cell itself)
 */
export const getFullGroupedNodePath = (rowNode: IRowNode): string => {
  const path = [];
  let currentNode: IRowNode | null = rowNode;
  while (currentNode?.key && currentNode?.id && currentNode?.id !== AG_GRID_ROOT_ROW_ID) {
    path.push(currentNode.key);
    currentNode = currentNode.parent;
  }
  return path.join('~');
};

/**
 * Calculates the population standard deviation of the grouped values
 * @param params - The params passed in by agGrid. The values field will contain the values of the grouped column
 */
export const standardDeviationAggFunc: IAggFunc = (params) => {
  const values = params.values.filter((value) => Number.isFinite(value));
  if (values.length === 0) {
    return null;
  }
  if (values.length === 1) {
    return 0;
  }
  return calculateStandardDeviationOfPopulation(values);
};

export const rangeAggFunc: IAggFunc = (params) => {
  const values = params.values.filter((value) => Number.isFinite(value));
  if (values.length === 0) {
    return null;
  }
  return findRange(values);
};

export const firstNonEmptyValueAggFunc: IAggFunc = (params) => {
  const values = params.values;
  const nonEmptyValues = values.filter((value) => !_.isNil(value) && value !== '' && value !== NULL_PLACEHOLDER);
  return _.first(nonEmptyValues) ?? null;
};

export const lastNonEmptyValueAggFunc: IAggFunc = (params) => {
  const values = params.values;
  const nonEmptyValues = values.filter((value) => !_.isNil(value) && value !== '' && value !== NULL_PLACEHOLDER);
  return _.last(nonEmptyValues) ?? null;
};

const calculateStandardDeviationOfPopulation = (values: number[]): number => {
  // Calculate mean
  const mean = values.reduce((sum, value) => sum + value, 0) / values.length;

  // Calculate squared differences from the mean
  const squaredDifferences = values.map((value) => Math.pow(value - mean, 2));

  // Calculate the variance
  const variance = squaredDifferences.reduce((sum, squaredDiff) => sum + squaredDiff, 0) / values.length;

  // Calculate the standard deviation (square root of the variance)
  return Math.sqrt(variance);
};

function findRange(numbers: number[]): number {
  const min = Math.min(...numbers);
  const max = Math.max(...numbers);
  return max - min;
}

export function resizeAgGridHelper(
  autoSizeColumnsDebounce: typeof autoSizeColumns,
  columnDefs: ColDef[],
  autoGroupColumn: ColDef | undefined,
  isInteractiveContent: boolean,
  agGridApi: GridApi | undefined,
  agGridWrapperElement: HTMLDivElement | null,
  showTable: boolean,
  setLastTransposed: (isTransposed: boolean) => void,
  isTransposed: boolean,
  rowCount: number,
  updateContentMeasurements?: (measurements: ContentMeasurements) => void,
  onAgGridReady?: () => void,
) {
  autoSizeColumnsDebounce(
    columnDefs.concat({ ...autoGroupColumn, colId: AG_GRID_GROUPING_COL_ID }),
    isInteractiveContent,
    agGridApi,
    agGridWrapperElement,
    (newWidthIfInteractive: number | undefined) => {
      if (!showTable) {
        setLastTransposed(isTransposed);
      }

      let widthToUse = newWidthIfInteractive;

      // The grouping bar will be too wide if the columns don't fill the screen, so resize it
      if (agGridWrapperElement && autoGroupColumn) {
        // The viewport and the container can each be too large depending on the sizing of the rest of the table,
        // so take the minimum value, which will either be the real size of the table or the size of the viewport
        const viewportWidth =
          agGridWrapperElement?.querySelector<HTMLDivElement>('.ag-header-viewport')?.offsetWidth ?? 0;
        const containerWidth =
          agGridWrapperElement?.querySelector<HTMLDivElement>('.ag-header-container')?.offsetWidth ?? 0;
        const wrapperWidth = Math.min(viewportWidth, containerWidth);

        // Calculate the width the bar actually needs from its contents, along with a buffer for the right side.
        const iconWidth =
          agGridWrapperElement?.querySelector<HTMLDivElement>('.ag-column-drop-title-bar')?.offsetWidth ?? 0;
        const bubblesWidth =
          agGridWrapperElement?.querySelector<HTMLDivElement>('.ag-column-drop-list')?.offsetWidth ?? 0;
        const columnDropBarWidthWithSpacing = iconWidth + bubblesWidth + 5;

        // Whichever one is bigger, give that to the wrapper
        const newWidth = Math.max(columnDropBarWidthWithSpacing, wrapperWidth);

        widthToUse = newWidth;
      }

      if (isInteractiveContent) {
        const ckTableParent = agGridWrapperElement?.closest('td.ck-editor__editable');
        const height = ckTableParent
          ? undefined
          : agGridWrapperElement?.querySelector('.ag-root-wrapper')?.scrollHeight;
        const isInteractiveWithMaxHeight = rowCount > MAX_INTERACTIVE_CONTENT_TABLE_ROWS && !headlessRenderMode();
        updateContentMeasurements?.({
          width: widthToUse,
          height: isInteractiveWithMaxHeight ? MAX_INTERACTIVE_CONTENT_TABLE_HEIGHT : height,
          isInteractiveWithMaxHeight,
        });
        const parent = agGridWrapperElement?.closest('.seeqContentWrapper') as HTMLDivElement;
        if (widthToUse) {
          parent?.style?.setProperty('max-width', `${widthToUse}px`);
          agGridWrapperElement
            ?.querySelector<HTMLDivElement>('.ag-column-drop-wrapper')
            ?.style?.setProperty('width', `${widthToUse}px`);
        }
        onAgGridReady?.();
      }

      finishAgGridLoading();
    },
  );
}

/**
 * When we sort via ag-grid we need to ensure that nulls are considered last, as this was the behavior of our
 * existing sort before ag-grid.
 *
 * @return 1 if value1 is greater than value2, -1 if value1 is less than value2, and 0 if they are equal
 */
export function agGridSortComparator(value1: ComparatorValue, value2: ComparatorValue) {
  if (value1 === value2) {
    return 0;
  } else if (_.isNil(value1)) {
    return 1;
  } else if (_.isNil(value2)) {
    return -1;
  } else {
    return value1 > value2 ? 1 : -1;
  }
}

/**
 * Limits the number of table rows for static table content to speed up rendering
 * and prevent the browser from crashing due to too much data
 */
export function restrictRowDataIfNecessary(rowData: AnyProperty[]): AnyProperty[] {
  if (headlessRenderMode() && rowData.length > MAX_HEADLESS_MODE_TABLE_ROWS) {
    return rowData
      .slice(0, MAX_HEADLESS_MODE_TABLE_ROWS)
      .concat({ remainingRows: rowData.length - MAX_HEADLESS_MODE_TABLE_ROWS });
  }

  return rowData;
}

export function getMoreRowsProps(rowCount: number): Pick<AgGridAsyncProps, 'isFullWidthRow' | 'fullWidthCellRenderer'> {
  return headlessRenderMode() && rowCount > MAX_HEADLESS_MODE_TABLE_ROWS
    ? {
        isFullWidthRow: (node) => node.rowNode.data.remainingRows > 1,
        fullWidthCellRenderer: MoreRowsMessage,
      }
    : {};
}
