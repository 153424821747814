
/*
    ====================================================================================================================
    | **NOTICE**
    | THIS IS A GENERATED FILE USING THE SOURCE FILE:
    |  crab\common\lib\jvm\seeq-names\src\main\java\com\seeq\common\seeqnames\SeeqNames.java
    ====================================================================================================================
*/
export const SeeqNames = {
    AuditTrail: {
        ConfigurationOption: 'ConfigurationOption',
    },
    ImpactReport: {
        ImpactTableName: 'VCC_ImpactTable_v1',
        Impact: 'impact',
        ImpactName: 'Name',
        ImpactDescription: 'Description',
        ImpactOwner: 'Impact Owner',
        ImpactStatus: 'Status',
        ImpactFormula: 'Formula',
        ImpactSavingsType: 'Savings Type',
        ImpactFrequency: 'Frequency',
        ImpactUnit: 'Unit',
        ImpactAsset: 'Asset',
        ImpactStartDate: 'Impact Start Date',
        ImpactEndDate: 'Impact End Date',
        ImpactQuantifyAmount: 'Impact Quantify Amount',
        AssignedToWorksheet: 'Worksheet Id',
        AssignedToWorkbook: 'Workbook Id',
        ImpactSite: 'Impact Site',
        Manager: 'Manager',
        ImpactLessonsLearned: 'Lesson Learned',
        ImpactFormulaParam: 'Formula Param',
        ImpactBasedOn: 'Based on',
    },
    SeeqContextNameSpace: {
        SeeqLabelNamespace: '__',
        UsageRestrictedLabelNamespace: '__Seeq.',
        VantageLabelCategoryNamespace: '__Vantage',
    },
    MlService: {
        IsolationForest: {
            Connection: {
                Name: 'Seeq ML Isolation Forest',
                Url: 'if',
                UdfPackage: 'seeqIF',
                UdfName: 'isolationForest',
            },
        },
        SelfOrganizingMap: {
            Connection: {
                Name: 'Seeq ML Self-Organizing Map',
                Url: 'som',
                UdfPackage: 'seeqSOM',
                UdfName: 'selfOrganizingMap',
            },
        },
        PlsRegression: {
            Connection: {
                Name: 'Seeq ML PLS Regression',
                Url: 'pls',
                UdfPackage: 'seeqPLS',
                UdfName: 'plsRegression',
            },
        },
    },
    Consumption: {
        Screenshot: 'Screenshot',
        Topic: 'Topic',
    },
    Capabilities: {
        LogViewer: {
            DataId: 'LogViewerCapability',
            CollectiveName: 'log viewers',
        },
        DatasourceAdministration: {
            DataId: 'DatasourceAdministrationCapability',
            CollectiveName: 'datasource administrators',
        },
        UserAdministration: {
            DataId: 'UserAdministrationCapability',
            CollectiveName: 'user administrators',
        },
        AuditTrail: {
            DataId: 'AuditTrailCapability',
            CollectiveName: 'audit trail viewers',
        },
        AnalyticsAdministration: {
            DataId: 'AnalyticsAdministrationCapability',
            CollectiveName: 'analytics administrators',
        },
        Administration: {
            DataId: 'Administrators',
            CollectiveName: 'administrators',
        },
    },
    RequestIdPrefixes: {
        Unique: 'R^',
        Screenshot: 'ScreenshotCapture^',
        Invocation: 'Invocation^',
    },
    PdfSettings: {
        MarginUnits: {
            Inches: 'in',
            Millimeters: 'mm',
            Centimeters: 'cm',
            Pixels: 'px',
        },
        PageSize: {
            Letter: 'Letter',
            Legal: 'Legal',
            A5: 'A5',
            A4: 'A4',
            A3: 'A3',
            Tabloid: 'Tabloid',
        },
        PageOrientation: {
            Portrait: 'Portrait',
            Landscape: 'Landscape',
        },
        Defaults: {
            PageOrientation: 'Portrait',
            PageSize: 'Letter',
            PageMargin: '0.5in',
        },
    },
    Database: {
        Utils: {
            EmptyGuid: '00000000-0000-0000-0000-000000000000',
        },
        Schemas: {
            MaterializedTables: 'materialized_tables',
        },
    },
    MaterializedTables: {
        DatumIdColumn: 'datum id',
        ItemIdColumn: 'item id',
        EventDataColumn: 'event data',
        ContextTablePrefix: 'context_',
        CreatedAtColumn: 'created at',
        UniqueRowId: 'Existing Row Identifier',
        Parameters: {
            PropertyName: 'propertyName',
            PropertyValue: 'propertyValue',
            PropertyMatchOperator: 'propertyMatchOperator',
            Level: 'level',
            Separator: 'separator',
            Formula: 'formula',
            ExcludeGloballyScoped: 'excludeGloballyScoped',
            ParametersName: 'parameters',
            Name: 'name',
            Description: 'description',
            ColumnIndexDataId: 'columnIndexDataId',
            ScopedTo: 'scopedTo',
            IdentityId: 'identityId',
            PermissionString: 'permissionString',
            SecurityString: 'securityString',
            ColumnIndex: 'columnIndex',
            ColumnIndexes: 'columnIndexes',
            ColumnIndexItem: 'columnIndexItem',
            ColumnIndexValue: 'columnIndexValue',
            ColumnIndexSourceText: 'columnIndexSourceText',
            ColumnIndexReplacement: 'columnIndexReplacement',
            ColumnIndexUnitOfMeasure: 'columnIndexUnitOfMeasure',
            Constant: 'constant',
            IsRoot: 'isRoot',
            ItemType: 'itemType',
            ItemTypes: 'itemTypes',
            Regex: 'regex',
            Replacement: 'replacement',
            RemoveNonMatches: 'removeNonMatches',
            LookupValue: 'lookupValue',
            VariableParameters: 'variableParameters',
            VariableParameterStrings: 'variableParameterStrings',
        },
        Rules: {
            Ancestor: 'ancestor',
            AssetCreator: 'assetCreator',
            ConcatColumns: 'concatColumns',
            Descendant: 'descendant',
            EventProperty: 'eventProperty',
            FormulaCreator: 'formulaCreator',
            GetItemProperty: 'getItemProperty',
            ItemSearch: 'itemSearch',
            Path: 'path',
            PathSearch: 'pathSearch',
            NumericScalarCreator: 'numericScalarCreator',
            SetItemProperty: 'setItemProperty',
            StringScalarCreator: 'stringScalarCreator',
            TextExtractor: 'textExtractor',
            TextReplacement: 'textReplacement',
            TreePathCreator: 'treePathCreator',
            Manager: 'manager',
            ItemType: 'itemType',
            ScalarCreator: {
                BaseScalarCreator: 'scalarCreator',
                NumericScalarCreator: 'numericScalarCreator',
                StringScalarCreator: 'stringScalarCreator',
            },
            Overrides: {
                Override: 'Override',
                BooleanOverride: 'booleanOverride',
                NumericOverride: 'numericOverride',
                StringOverride: 'stringOverride',
                TimestampOverride: 'timestampOverride',
                UUIDOverride: 'uuidOverride',
            },
            Constant: {
                BaseConstant: 'constant',
                BooleanConstant: 'booleanConstant',
                NumericConstant: 'numericConstant',
                StringConstant: 'stringConstant',
                TimestampConstant: 'timestampConstant',
                UUIDConstant: 'uuidConstant',
                BooleanLookupConstant: 'booleanLookupConstant',
                NumericLookupConstant: 'numericLookupConstant',
                StringLookupConstant: 'stringLookupConstant',
                TimestampLookupConstant: 'timestampLookupConstant',
                UUIDLookupConstant: 'uuidLookupConstant',
            },
        },
    },
    Injection: {
        SystemState: 'SystemState',
        SystemUser: 'SystemUser',
        TreeDefinition: 'TreeDefinition',
    },
    Ignition: {
        AgentName: 'Ignition Module Agent',
    },
    Connectors: {
        SeeqDeveloperName: 'Seeq',
        UnknownThirdPartyDeveloperName: 'third-party developer',
        ConnectionsJsonKey: 'Connections',
        DatasourceManagedJsonKey: 'DatasourceManaged',
        Connections: {
            NameJsonKey: 'Name',
            DatasourceIdJsonKey: 'Id',
            EnabledJsonKey: 'Enabled',
            IndexingJsonKey: 'Indexing',
            FrequencyJsonKey: 'Frequency',
            NextIndexingAtJsonKey: 'Next',
            OnStartupAndConfigChangeJsonKey: 'OnStartupAndConfigChange',
            TransformsJsonKey: 'Transforms',
            MaxConcurrentRequestsJsonKey: 'MaxConcurrentRequests',
            MaxResultsPerRequestJsonKey: 'MaxResultsPerRequest',
            FieldConfigurationsJsonKey: 'FieldConfigurations',
            SyncStatus: {
                Unknown: 'SYNC_UNKNOWN',
                Initializing: 'SYNC_INITIALIZING',
                InProgress: 'SYNC_IN_PROGRESS',
                ArchivingDeletedItems: 'SYNC_ARCHIVING_DELETED_ITEMS',
                Complete: 'SYNC_COMPLETE',
                Success: 'SYNC_SUCCESS',
                Failed: 'SYNC_FAILED',
            },
            Status: {
                Connecting: 'CONNECTING',
                Connected: 'CONNECTED',
                Disconnected: 'DISCONNECTED',
                Disabled: 'DISABLED',
            },
            SyncMode: {
                None: 'NONE',
                Incremental: 'INCREMENTAL',
                Full: 'FULL',
            },
            SyncResult: {
                InProgress: 'IN_PROGRESS',
                Success: 'SUCCESS',
                Failed: 'FAILED',
                Interrupted: 'INTERRUPTED',
            },
            MultivaluedSamplesBehavior: {
                KeepLast: 'Keep Last',
                Stagger: 'Stagger',
            },
        },
    },
    Agents: {
        AgentKeysFolderName: 'agent-keys',
        ConnectorsJsonKey: 'Connectors',
        WebSockets: {
            PingPrefix: 'SEEQ-WS-PING-MSG-',
        },
        Status: {
            Connecting: 'CONNECTING',
            Connected: 'CONNECTED',
            Disconnected: 'DISCONNECTED',
            PreProvisioned: 'PREPROVISIONED',
        },
        Connectors: {
            NameJsonKey: 'Name',
            EnabledJsonKey: 'Enabled',
        },
    },
    TreeType: {
        SeeqWorkbench: 'Seeq Workbench',
    },
    OData: {
        ExportEntitySetSuffix: '_DataSet',
        ODataV4ServiceSuffix: '.svc/',
    },
    CleanUpJobsGroup: {
        AnnotationImages: 'AnnotationOrphanedImageCleanerJob',
        WorksheetMaintenance: 'WorksheetMaintenanceJob',
    },
    JobGroup: {
        Screenshot: 'ScreenshotCapture',
        DateRange: 'DateRangeJobs',
        Report: 'ReportJobs',
        Notebook: 'NotebookJobs',
        CleanUp: 'CleanUpJobs',
        ReportTemplate: 'ReportTemplateJobs',
        ConditionMonitor: 'ConditionMonitorNotifications',
        ItemFinder: 'ItemFinder',
    },
    Logging: {
        MigrationsCompleteFile: 'migrations_complete_',
        RemoteAgents: {
            MDCFilenameKey: 'agentlogfilename',
        },
        Appserver: {
            MDCFilenameKey: 'logfilename',
            DefaultFile: 'appserver',
            StartupFile: 'appserver-startup',
            UpgradeFile: 'appserver-upgrade',
            AsyncUpgradeFile: 'appserver-async-upgrade',
            LicensingFile: 'appserver-licensing',
            Requests: 'appserver-requests',
            RequestMonitors: 'appserver-requestmonitors',
            Units: 'appserver-units',
            Users: 'appserver-users',
            IntervalAnalysis: {
                FetchCritic: 'appserver-intervalanalysis-fetchcritic',
            },
        },
    },
    RemoteDatasourceClasses: {
        LDAP: 'LDAP',
        OAuth2: 'OAuth 2.0',
        WindowsAuth: 'Windows Auth',
        ADX: 'ADX',
        APRM: 'AspenTech APRM',
        BigQuery: 'BigQuery',
        ChaosMonkey: 'Chaos Monkey',
        Cognite: 'Cognite',
        CygNet: 'CygNet',
        ExampleData: 'Time Series CSV Files',
        AddOnCalcPython: 'Add-on Calculation Python',
        AddOnCalcWebhook: 'Add-on Calculation Webhook',
        AddOnCalcExcel: 'Add-on Calculation Excel VBA',
        FileFolders: 'FileFolders',
        GEProficy: 'GE Proficy',
        GPM: 'GreenPowerMonitor',
        IBAHD: 'IbaHD',
        Ignition: 'Ignition Gateway',
        InfluxDB: 'InfluxDB',
        InfluxDB2: 'InfluxDB 2',
        IP21: 'AspenTech IP21',
        MetNet: 'MetNet',
        NOAAWeather: 'NOAA Weather Service',
        Omnia: 'Equinor Omnia Plant',
        OPCHDA: 'OPC-HDA',
        OPCUA: 'OPC-UA',
        OSIsoftAF: 'OSIsoft AF',
        OSIsoftAFEventFrames: 'OSIsoft AF Event Frames',
        OSIsoftPI: 'OSIsoft PI',
        SeeqToSeeq: 'Seeq to Seeq',
        SQL: 'SQL',
        StressTest: 'Stress Test',
        TimeStream: 'TimeStream',
        TreeFile: 'Tree File',
        WITSML: 'WITSML',
        Wonderware: 'Wonderware',
        XHQ: 'XHQ',
    },
    SeeqSaasAdminDatasource: {
        DatasourceClass: 'OAuth 2.0',
        DatasourceId: 'Seeq Saas Admin',
        DatasourceName: 'Seeq Saas Admin',
    },
    IdentityProviderDatasourceClasses: {
        LDAP: 'LDAP',
        OAuth2: 'OAuth 2.0',
        SeeqAuth: 'Auth',
        WindowsAuth: 'Windows Auth',
    },
    LocalDatasources: {
        MaterializedTables: {
            DatasourceClass: 'Seeq Materialized Tables',
        },
        SeeqDataLab: {
            DatasourceClass: 'Seeq Data Lab',
        },
        SeeqMetadataItemStorage: {
            DatasourceClass: 'Seeq Metadata',
            DatasourceId: 'Seeq Metadata',
            DatasourceName: 'Seeq Metadata',
        },
        SeeqCalculationItemStorage: {
            DatasourceClass: 'Seeq Calculations',
            DatasourceId: 'Seeq Calculations',
            DatasourceName: 'Seeq Calculations',
        },
        Monitors: {
            DatasourceClass: 'Monitors',
            DatasourceId: 'monitors',
            DatasourceName: 'Seeq Monitors',
        },
        Authentication: {
            DatasourceClass: 'Auth',
            DatasourceId: 'Seeq',
            DatasourceName: 'Seeq',
            PasswordSpecialCharacters: '!@#$%^&*()_+-=[]{}|;:,.<>?/',
        },
        FileSignalStorage: {
            DatasourceClass: 'Seeq - Signal Datasource',
            DatasourceId: 'Signal Datasource',
            DatasourceName: 'Seeq Signal Datasource',
        },
        ContextDatumStorage: {
            DatasourceClass: 'Context Datum Storage',
            DatasourceId: 'Context Datum Storage',
            DatasourceName: 'Context Datum Storage',
        },
        PostgresDatums: {
            DatasourceClass: 'Postgres Datums',
            DatasourceId: 'default',
            DatasourceName: 'Seeq Conditions Database',
        },
        Datafile: {
            DatasourceClass: 'Imported CSV Files',
            DatasourceId: 'Imported CSV Files',
            DatasourceName: 'Imported CSV Files',
        },
    },
    SwaggerApiNames: {
        Agents: 'Agents',
        AddOns: 'AddOns',
        Audit: 'Audit',
        Auth: 'Auth',
        Assets: 'Assets',
        Folders: 'Folders',
        Networks: 'Networks',
        Items: 'Items',
        Reports: 'Reports',
        Jobs: 'Jobs',
        Logs: 'Logs',
        Trees: 'Trees',
        SampleSeries: 'SampleSeries',
        Signals: 'Signals',
        Capsules: 'Capsules',
        Conditions: 'Conditions',
        Metrics: 'Metrics',
        Formulas: 'Formulas',
        Functions: 'Functions',
        Requests: 'Requests',
        Tables: 'Tables',
        Workbooks: 'Workbooks',
        Worksheets: 'Worksheets',
        Export: 'Export',
        Annotations: 'Annotations',
        Datasources: 'Datasources',
        Datafiles: 'Datafiles',
        Users: 'Users',
        UserGroups: 'UserGroups',
        AccessKeys: 'AccessKeys',
        Usage: 'Usage',
        Scalars: 'Scalars',
        Subscriptions: 'Subscriptions',
        System: 'System',
        Import: 'Import',
        Monitors: 'Monitors',
        Projects: 'Projects',
        Content: 'Content',
        ReportTemplates: 'ReportTemplates',
        ConditionMonitors: 'ConditionMonitors',
        Plugins: 'Plugins',
        Displays: 'Displays',
        DisplayTemplates: 'DisplayTemplates',
        NotificationConfigurations: 'NotificationConfigurations',
        Label: 'Label',
        Context: 'Context',
        TableDefinitions: 'TableDefinitions',
        Models: 'Models',
        TableOverrides: 'TableOverrides',
        GraphQL: 'GraphQL',
        Unstable: 'Unstable',
        Scim: 'SCIM',
    },
    Channels: {
        AgentsStatus: 'agents-status',
        DatasourcesStatus: 'datasources-status',
        Broadcast: 'broadcast',
        LiveScreenshot: 'live-screenshot',
        RequestsProgress: 'requests-progress',
        WorkbookChannel: 'workbook',
        WorkstepChannel: 'workstep',
        ReportUpdateChannel: 'report-update',
        TableMaterializerChannel: 'table-materializer',
        LockingChannel: 'workbook-locking',
    },
    API: {
        Ace: '/ace',
        AddOns: '/add-ons',
        AddOnTools: '/addOnTools',
        Agents: '/agents',
        Audit: '/audit',
        Auth: '/auth',
        Assets: '/assets',
        GenAI: '/genai',
        Folders: '/folders',
        Networks: '/networks',
        Items: '/items',
        ItemFinders: '/finders',
        Reports: '/reports',
        Jobs: '/jobs',
        Logs: '/logs',
        Batch: '/batch',
        Trees: '/trees',
        AssetTrees: '/trees/assets',
        Signals: '/signals',
        Samples: '/samples',
        PlotSamples: '/plot-samples',
        Capsules: '/capsules',
        Conditions: '/conditions',
        ConditionMonitors: '/condition-monitors',
        Metrics: '/metrics',
        Thresholds: '/thresholds',
        Formulas: '/formulas',
        Docs: '/docs',
        Functions: '/functions',
        Packages: '/packages',
        Scalars: '/scalars',
        Properties: '/properties',
        Requests: '/requests',
        Tables: '/tables',
        Workbooks: '/workbooks',
        Worksheets: '/worksheets',
        Worksteps: '/worksteps',
        Export: '/export',
        Annotations: '/annotations',
        Datasources: '/datasources',
        Datafiles: '/datafiles',
        Users: '/users',
        UserGroups: '/usergroups',
        AccessKeys: '/accesskeys',
        OData: '/odata.svc',
        ODataV4: '/odata',
        System: '/system',
        Import: '/import',
        Status: '/status',
        Shutdown: '/shutdown',
        Screenshots: '/screenshots',
        Subscriptions: '/subscriptions',
        Monitors: '/system/monitors',
        Configuration: '/system/configuration',
        Installer: '/system/installer',
        Projects: '/projects',
        Content: '/content',
        ReportTemplates: '/report-templates',
        DateRanges: '/date-ranges',
        AssetSelections: '/asset-selections',
        Plugins: '/plugins',
        Displays: '/displays',
        DisplayTemplates: '/display-templates',
        Usage: '/usage',
        NotificationConfigurations: '/notification-configurations',
        Labels: '/labels',
        Context: '/context',
        ContextComments: '/comments',
        ContextLabels: '/labels',
        ContextOpaque: '/opaque',
        ContextNumeric: '/numeric',
        TableDefinitions: '/table-definitions',
        Models: '/models',
        GraphQL: '/graphql',
        Cache: '/cache',
        ScimUsers: 'Users',
        ScimGroups: 'Groups',
        ScimToken: 'token',
        ErrorMessages: {
            AttemptedToSetScopeOnAGloballyScopedItem: 'Attempted to set scope on a globally scoped item',
            CancelledDueToTooManyRetries: 'The request was canceled due to being retried too many times',
        },
        AddOnToolLinkType: {
            Window: 'window',
            Tab: 'tab',
            None: 'none',
        },
        AddOnToolLaunchLocation: {
            Homescreen: 'homescreen',
            ToolsPane: 'toolsPane',
        },
        PropertyIds: {
            RequestInformation: 'com.seeq.requestinformation',
        },
        Cookies: {
            Auth: 'sq-auth',
            Csrf: 'sq-csrf',
        },
        QueryParams: {
            Admin: 'admin',
            ClientInteractiveSessionId: 'interactiveId',
            Creator: 'creator',
            Name: 'name',
            requestId: 'requestId',
            ContentCount: 'contentCount',
        },
        Headers: {
            AcceptRanges: 'Accept-Ranges',
            Async: 'x-sq-async',
            AsyncJob: 'x-sq-async-job',
            Auth: 'x-sq-auth',
            IdentityPath: 'x-sq-identity-path',
            ContentRange: 'Content-Range',
            Csrf: 'x-sq-csrf',
            ForwardedFor: 'x-forwarded-for',
            InteractiveSessionId: 'Session-Id',
            PoolId: 'x-sq-pool-id',
            PoolConnectedOnDegradedState: 'x-sq-pool-connected-on-degraded-state',
            Range: 'Range',
            RequestId: 'x-sq-request-id',
            RequestOrigin: 'x-sq-origin',
            RequestOriginURL: 'x-sq-origin-url',
            RequestOriginLabel: 'x-sq-origin-label',
            Server: 'Server',
            ServerMeters: 'Server-Meters',
            ServerTiming: 'Server-Timing',
            Expect: 'Expect',
            DateRangeStart: 'x-sq-daterange-start',
            DateRangeEnd: 'x-sq-daterange-end',
            Timings: {
                Datasource: 'Datasource',
                Cache: 'Cache',
                Processing: 'Processing',
                GC: 'GC',
                RequestQueue: 'Request Queue',
                CalcEngineQueue: 'Calc Engine Queue',
                SeeqDatabase: 'Seeq Database',
                Total: 'Total',
            },
            Meters: {
                DatasourceSamplesRead: 'Datasource Samples Read',
                DatasourceCapsulesRead: 'Datasource Capsules Read',
                CacheSamplesRead: 'Cache Samples Read',
                CacheCapsulesRead: 'Cache Capsules Read',
                CacheInMemorySamplesRead: 'Cache In-Memory Samples Read',
                CacheInMemoryCapsulesRead: 'Cache In-Memory Capsules Read',
                DatabaseItemsRead: 'Database Items Read',
                DatabaseRelationshipsRead: 'Database Relationships Read',
            },
        },
        Flags: {
            AllProperties: '@allProperties',
            ExcludeGloballyScoped: '@excludeGloballyScoped',
            IncludeUnsearchable: '@includeUnsearchable',
        },
        TranslationKeyPrefixes: {
            Capabilities: 'API.CAPABILITIES.',
        },
    },
    UnitTest: {
        Timestamp1: '1990-03-07T09:58:20.888888Z',
        Timestamp2: '1990-04-08T10:59:21.999999Z',
        NonExistentGuid: '1CB64296-FC3E-4221-9A7F-947A0AB99807',
    },
    Edges: {
        Owns: 'owns',
        Created: 'created',
        Installed: 'installed',
        Contains: 'contains',
        FolderContains: 'folder contains',
        MemberOf: 'member of',
        InterestedIn: 'interested in',
        ReportsOn: 'reports on',
        RepliesTo: 'replies to',
        RecentlyAccessed: 'recently accessed',
        HasWorksheet: 'has worksheet',
        HasWorkstep: 'has workstep',
        CurrentWorkstep: 'current workstep',
        TemplateWorkstep: 'template workstep',
        HasParameter: 'has parameter',
        UsesFunction: 'uses function',
        AssociatedTo: 'associated to',
        /* DEPRECATED, Deprecated. See SeeqNames.java for more info */
        AccessFor: 'access for',
        /* DEPRECATED, Deprecated. See SeeqNames.java for more info */
        HasAce: 'has access control entry',
        HasPinned: 'has pinned',
        Measuring: 'measuring',
        HasAggregationSignal: 'has aggregation signal',
        HasAggregationCondition: 'has aggregation condition',
        HasAggregationFormulaFunction: 'has aggregation formula function',
        BoundedBy: 'bounded by',
        HasThreshold: 'has threshold',
        HasConditionThreshold: 'has condition threshold',
        HomeFolder: 'home folder',
        HasManager: 'has manager',
        PackageContainsUDF: 'package contains user defined function',
        PackageContainsDoc: 'package contains formula doc',
        DocumentedBy: 'documented by',
        AgentContainsConnector: 'agent contains connector',
        ConnectionCreatedByConnector: 'connection created by connector',
        Renders: 'renders',
        RunsNotebook: 'runs notebook',
        SourceWorksheet: 'source worksheet',
        SourceWorkstep: 'source workstep',
        HasAsset: 'has asset',
        HasAssetSelection: 'has asset selection',
        HasAssetSelections: 'has asset selections',
        HasCondition: 'has condition',
        HasItemFinder: 'has item finder',
        HasContent: 'has content',
        HasDateRange: 'has date range',
        HasDateRanges: 'has date ranges',
        HasDesignatedScimUser: 'has designated scim user',
        HasScheduledNotebook: 'has scheduled notebook',
        HasTemplate: 'has template',
        SubscribedTo: 'subscribed to',
        ContainsContextFor: 'contains context for',
    },
    Types: {
        Asset: 'Asset',
        AssetSelection: 'AssetSelection',
        AccessControlEntry: 'AccessControlEntry',
        StoredSignal: 'StoredSignal',
        Datasource: 'Datasource',
        Datafile: 'Datafile',
        CalculatedSignal: 'CalculatedSignal',
        Signal: 'Signal',
        TreeDefinition: 'TreeDefinition',
        Identity: 'Identity',
        User: 'User',
        UserGroup: 'UserGroup',
        AccessKey: 'AccessKey',
        SystemState: 'SystemState',
        Export: 'Export',
        ProjectLink: 'ProjectLink',
        AddOnTool: 'AddOnTool',
        BaseAnnotation: 'BaseAnnotation',
        Report: 'Report',
        ReportTemplate: 'ReportTemplate',
        Journal: 'Journal',
        Reply: 'Reply',
        ItemFinder: 'ItemFinder',
        Condition: 'Condition',
        ConditionMonitor: 'ConditionMonitor',
        Capsule: 'Capsule',
        StoredCondition: 'StoredCondition',
        CalculatedCondition: 'CalculatedCondition',
        Scalar: 'ScalarItem',
        LiteralScalar: 'LiteralScalar',
        CalculatedScalar: 'CalculatedScalar',
        FormulaFunction: 'FormulaFunction',
        AggregatingFormulaFunction: 'AggregatingFormulaFunction',
        Chart: 'Chart',
        UserDefinedFormulaFunction: 'UserDefinedFormulaFunction',
        FormulaPackage: 'FormulaPackage',
        FormulaDoc: 'FormulaDoc',
        FormulaPackageDoc: 'FormulaPackageDoc',
        ThresholdMetric: 'ThresholdMetric',
        Display: 'Display',
        DisplayTemplate: 'DisplayTemplate',
        Folder: 'Folder',
        Project: 'Project',
        Content: 'Content',
        ImageContent: 'ImageContent',
        ReactJsonContent: 'ReactJsonContent',
        DateRange: 'DateRange',
        ScheduledNotebook: 'ScheduledNotebook',
        AddOn: 'AddOn',
        Plugin: 'Plugin',
        Connection: 'Connection',
        Connector: 'Connector',
        Agent: 'Agent',
        Analysis: 'Analysis',
        Topic: 'Topic',
        Vantage: 'Vantage',
        Worksheet: 'Worksheet',
        Workstep: 'Workstep',
        Item: 'Item',
        ItemWithOwner: 'ItemWithOwner',
        TypeDefinition: 'TypeDefinition',
        TableDefinition: 'TableDefinition',
        VersionHistory: 'VersionHistory',
    },
    TopicDocumentAttributes: {
        DataSeeqContent: 'data-seeq-content',
        DataSeeqContentPending: 'data-seeq-content-pending',
        DataSeeqDateRangeId: 'data-date-range-id',
        DataSeeqDateRangeFormat: 'data-date-range-format',
        DataSeeqDateRangeContent: 'data-date-range-content',
        DataSeeqContentPercentWidth: 'data-seeq-content-percent-width',
        DataSeeqContentNoMargin: 'data-seeq-content-no-margin',
        DataSeeqContentBorder: 'data-seeq-content-border',
        DataSeeqContentHeight: 'data-seeq-content-height',
        DataSeeqContentWidth: 'data-seeq-content-width',
        DataSeeqContentPropertyOverrides: 'data-seeq-content-property-overrides',
        DataSeeqAssetSelectionId: 'data-asset-selection-id',
        DataSeeqAssetSelectionDepthLevel: 'data-asset-selection-depth-level',
        DataSeeqIframeContentUrl: 'data-iframe-content-url',
        DataSeeqIframeContentHeight: 'data-iframe-content-height',
        DataSeeqIframeContentWidth: 'data-iframe-content-width',
    },
    CapsuleProperties: {
        Start: 'Start',
        End: 'End',
        Duration: 'Duration',
        IsUncertain: 'IsUncertain',
        IsBounded: 'IsBounded',
        Middle: 'Middle',
        Count: 'Count',
        Value: 'Value',
        Unit: 'Unit',
        Distance: 'Distance',
        Similarity: 'Similarity',
        StandardDeviation: 'Standard Deviation',
        SamplePeriod: 'Sample Period',
        ReferenceCapsule: 'Reference Capsule',
        ConditionId: 'Condition ID',
        CapsuleId: 'Capsule ID',
        CapsuleIdSafe: 'Capsule SortKey',
        OriginalUncertainty: 'Original Uncertainty',
        IsSuppressed: 'Is Suppressed',
    },
    Properties: {
        Guid: 'GUID',
        Name: 'Name',
        Id: 'ID',
        Description: 'Description',
        Unsearchable: 'Unsearchable',
        Archived: 'Archived',
        ArchivalId: 'Archival ID',
        ArchivedAt: 'Archived At',
        ArchivedReason: 'Archived Reason',
        Dashboard: 'Dashboard',
        SyncToken: 'Sync Token',
        NumberFormat: 'Number Format',
        SourceNumberFormat: 'Source Number Format',
        StringFormat: 'String Format',
        SourceStringFormat: 'Source String Format',
        StorageLocation: 'Storage Location',
        ExecutorId: 'Executor ID',
        CreatorId: 'Creator ID',
        CreatorFirstName: 'Creator First Name',
        CreatorLastName: 'Creator Last Name',
        HistoricalDataVersion: 'Historical Data Version',
        ManualCdc: 'Manual CDC',
        ScopedTo: 'Scoped To',
        Asset: 'asset',
        FullPath: 'fullpath',
        Locked: 'Locked',
        LockedMetadata: 'Locked Metadata',
        ImportItemType: 'Import Item Type',
        Filename: 'Filename',
        NameRow: 'Name Row',
        NamePrefix: 'Name Prefix',
        NameSuffix: 'Name Suffix',
        ConditionName: 'Condition Name',
        FirstDataRow: 'First Data Row',
        DescriptionRow: 'Description Row',
        InterpolationMethodRow: 'Interpolation Method Row',
        MaximumInterpolationRow: 'Maximum Interpolation Row',
        ValueUomRow: 'Value Unit of Measure Row',
        FieldDelimiter: 'Field Delimiter',
        DayFirstDefault: 'Day First Default',
        KeyColumnName: 'Key Column Name',
        KeyColumnIndex: 'Key Column Index',
        EndColumnName: 'End Column Name',
        EndColumnIndex: 'End Column Index',
        KeyFormat: 'Key Format',
        TimeZone: 'Time Zone',
        ValueColumnNames: 'Value Column Names',
        ValueColumnIndices: 'Value Column Indices',
        Append: 'Append',
        ValidationMode: 'Validation Mode',
        LenientDaylightSavings: 'Lenient Daylight Savings',
        DatasourceClass: 'Datasource Class',
        DatasourceName: 'Datasource Name',
        DatasourceId: 'Datasource ID',
        DatasourceHostId: 'Datasource Host ID',
        DataId: 'Data ID',
        PreviousDataId: 'Previous Data ID',
        StoredInSeeq: 'Stored In Seeq',
        ConnectionRequestTimeout: 'Connection Request Timeout',
        MaxRequests: 'Max Requests',
        WindowsAuthMode: 'Windows Auth Mode',
        LdapAuthMode: 'LDAP Auth Mode',
        UsernamePasswordCompatible: 'Username/Password Compatible',
        UsernameHumanReadable: 'Username Human Readable',
        ExpectDuplicatesDuringIndexing: 'Expect Duplicates During Indexing',
        ReferencedDatasourceId: 'Referenced Datasource ID',
        ReferencedDatasourceClass: 'Referenced Datasource Class',
        AdditionalGroupsToSync: 'Additional Groups To Sync',
        SignalLocation: 'Signal Location',
        ConditionLocation: 'Condition Location',
        CurrentIndexAt: 'Current Index At',
        PreviousIndexAt: 'Previous Index At',
        SyncResult: 'Sync Result',
        IndexingScheduleSupported: 'Indexing Schedule Supported',
        SyncMode: 'Sync Mode',
        ItemsArchivedCount: 'Items Archived Count',
        IndexingDuration: 'Indexing Duration',
        ProvidesEveryoneGroupIdentities: 'Provides Everyone Group Identities',
        DatasourceLabels: 'Datasource Labels',
        DatasourceVendor: 'Datasource Vendor',
        IndexingProgressTimestamp: 'Indexing Progress Timestamp',
        AssetProgress: 'Asset Progress',
        AssetCount: 'Asset Count',
        PreviousAssetCount: 'Previous Asset Count',
        SignalProgress: 'Signal Progress',
        SignalCount: 'Signal Count',
        PreviousSignalCount: 'Previous Signal Count',
        ConditionProgress: 'Condition Progress',
        ConditionCount: 'Condition Count',
        PreviousConditionCount: 'Previous Condition Count',
        ScalarProgress: 'Scalar Progress',
        ScalarCount: 'Scalar Count',
        PreviousScalarCount: 'Previous Scalar Count',
        RelationshipProgress: 'Relationship Progress',
        RelationshipCount: 'Relationship Count',
        PreviousRelationshipCount: 'Previous Relationship Count',
        UserGroupProgress: 'UserGroup Progress',
        UserGroupCount: 'UserGroups Count',
        PreviousUserGroupCount: 'Previous UserGroups Count',
        StaleMetadata: 'Stale Metadata',
        InterpolationMethod: 'Interpolation Method',
        SourceMaximumInterpolation: 'Source Maximum Interpolation',
        OverrideMaximumInterpolation: 'Override Maximum Interpolation',
        MaximumInterpolation: 'Maximum Interpolation',
        ValueUom: 'Value Unit Of Measure',
        SourceValueUom: 'Source Value Unit Of Measure',
        DisplayUnit: 'Display Unit',
        UnitsMigrated: 'Units Migrated',
        MaximumDuration: 'Maximum Duration',
        CacheEnabled: 'Cache Enabled',
        UncertaintyOverride: 'Uncertainty Override',
        MetadataProperties: 'Metadata Properties',
        LastKeyWritten: 'Last Key Written',
        RequestIntervalClamp: 'Request Interval Clamp',
        OldCacheId: 'Old Cache ID',
        DebounceDuration: 'Debounce Duration',
        DebounceWindow: 'Debounce Window',
        TombstonedProperties: 'Tombstoned Properties',
        MultivaluedSamplesBehavior: 'Multivalued Samples Behavior',
        CapsuleIdProperty: 'Capsule ID Property',
        StoredSeriesCacheVersion: 'Stored Series Cache Version',
        ReplaceCapsuleProperties: 'Replace Capsule Properties',
        Uom: 'Unit Of Measure',
        FormulaVersion: 'Formula Version',
        Formula: 'Formula',
        SwapSourceId: 'Swap Source ID',
        SwapSourceUom: 'Source Value Unit Of Measure',
        SwapKey: 'Swap Key',
        CacheValue: 'CacheValue',
        Value: 'Value',
        FormulaParameters: 'FormulaParameters',
        IsModifiedOutsideTable: 'Is Modified Outside Table',
        ExpectedProvidedType: 'ExpectedProvidedType',
        VersionString: 'Version String',
        CreatorName: 'Creator Name',
        CreatorContactInfo: 'Creator Contact Info',
        Title: 'Title',
        Examples: 'Examples',
        SearchKeywords: 'Search Keywords',
        ExperimentalPackageName: 'Experimental',
        SeeqPackageName: 'Seeq',
        IndexDocName: 'index',
        AggregationFunction: 'AggregationFunction',
        Duration: 'Duration',
        Period: 'Period',
        MeasuredItemMaximumDuration: 'MeasuredItemMaximumDuration',
        MeasuredItemId: 'MeasuredItemId',
        MetricConfigurationMigration: 'MetricReconfigurationMigration',
        BoundingConditionMaximumDuration: 'BoundingConditionMaximumDuration',
        NeutralColor: 'NeutralColor',
        IsGenerated: 'Is Generated',
        TreeType: 'Tree Type',
        ManuallyAdded: 'Manually Added',
        CreatedBy: 'Created By',
        AssetPath: 'assetPath',
        ColumnType: 'columnType',
        OriginalMapping: 'originalMapping',
        ParameterToColumn: 'parameterToColumn',
        SwapSourceAssetId: 'Swap Source Asset ID',
        ExportData: 'Export Data',
        IsCapsuleView: 'Is Capsule View',
        IsExportCapsuleTable: 'Is Export Capsule Table',
        IsChainView: 'Is Chain View',
        IsAutoupdateTimeRange: 'Is Autoupdate Time Range',
        GridSize: 'Grid Size',
        UserEmail: 'User Email',
        Username: 'Username',
        FirstName: 'First Name',
        LastName: 'Last Name',
        PasswordHash: 'Password Hash',
        PasswordHashMethod: 'Password Hash Method',
        PasswordSetDate: 'Password Set Date',
        LastLoginAt: 'Last Login At',
        FailedLoginAttemptCount: 'Failed Login Attempt Count',
        SessionDuration: 'Session Duration',
        AccessKeyValidityDuration: 'Access Key Validity Duration',
        Enabled: 'Enabled',
        Workbench: 'Workbench',
        UserRole: 'User Role',
        Site: 'Site',
        UserLanguage: 'userLanguage',
        RequirePasswordResetOnNextLogin: 'Require Password Reset On Next Login',
        AgentProvisioningStatus: 'Agent Provisioning Status',
        AgentProvisioningSourceAddress: 'Agent Provisioning Source Address',
        AgentProvisioningRequestedAt: 'Agent Provisioning Requested At',
        PasswordResetTokenHash: 'Password Reset Token Hash',
        PasswordResetTokenExpirationAt: 'Password Reset Token Expiration At',
        LastPasswordResetRequestAt: 'Last Password Reset Request At',
        RemoteGroupEditable: 'Remote Group Editable',
        RemovePermissions: 'Remove Permissions',
        Data: 'Data',
        CreatedAt: 'Created At',
        UpdatedAt: 'Updated At',
        Order: 'Order',
        RemovedWorksheetWorkstepRelationships: 'Removed Worksheet Workstep Relationships',
        Document: 'Document',
        PlainTextDocument: 'Plain Text Document',
        Discoverable: 'Discoverable',
        PublishedAt: 'Published At',
        LastViewedAt: 'Last Viewed At',
        SourceSecurityString: 'Source Security String',
        SecurityString: 'Security String',
        PermissionsFromDatasource: 'Permissions From Datasource',
        PermissionInheritanceDisabled: 'Permission Inheritance Disabled',
        AssetGroupMember: 'Asset Group Member',
        FirstAdminCreated: 'First Admin Created',
        UIConfig: 'UIConfig',
        DirtyCache: 'Dirty Cache',
        Unmodifiable: 'Unmodifiable',
        Height: 'Height',
        Width: 'Width',
        Scale: 'Scale',
        Timezone: 'Timezone',
        Selector: 'Selector',
        LastScreenshotDuration: 'Last Screenshot Duration',
        ScreenshotWarning: 'Screenshot Warning',
        SummaryType: 'Summary Type',
        SummaryValue: 'Summary Value',
        HideUncertainty: 'Hide Uncertainty',
        CurrentFilename: 'Current Filename',
        LastResultHash: 'Last Result Hash',
        CacheId: 'Cache ID',
        AssetPathDepth: 'Asset Path Depth',
        LastRunAt: 'Last Run At',
        LastRunState: 'Last Run State',
        QueryRangeLookAhead: 'Query Range Look Ahead',
        WebhookUrl: 'Webhook Url',
        FirstRunLookBack: 'First Run Look Back',
        FinderConfigurations: 'Finder Configurations',
        LastRunWarnings: 'Last Run Warnings',
        ResourceSize: 'Resource Size',
        ProjectType: 'Project Type',
        CronSchedule: 'Cron Schedule',
        Background: 'Background',
        ConditionFormulaNow: 'Condition Formula Now',
        NotebookPath: 'Notebook Path',
        PreviousRunTime: 'Previous Run Time',
        CronSchedulesToIndices: 'Cron Schedules To Indices',
        Label: 'Label',
        NotifyOnSkippedExecution: 'Notify On Skipped Execution',
        NotifyOnAutomaticUnschedule: 'Notify On Automatic Unschedule',
        TotalRunTime: 'Total Run Time',
        AverageRunTime: 'Average Run Time',
        IsFixedWith: 'Is Fixed Width',
        MarginTop: 'Margin Top',
        MarginBottom: 'Margin Bottom',
        MarginLeft: 'Margin Left',
        MarginRight: 'Margin Right',
        Orientation: 'Page Orientation',
        PageSize: 'Page Size',
        IsCkEnabled: 'Is CK Enabled',
        FroalaBackup: 'Froala Backup',
        OriginalHasWorkstepGuid: 'Original HasWorkstep GUID',
        InDevelopment: 'In Development',
        IconClass: 'Icon Class',
        TargetUrl: 'Target URL',
        LaunchLocation: 'Launch Location',
        LinkType: 'Link Type',
        WindowDetails: 'Window Details',
        ReuseWindow: 'Reuse Window',
        SortKey: 'Sort Key',
        AddOnIdentifier: 'Add-on Identifier',
        AddOnComponents: 'Add-on Components',
        HtmlTemplate: 'HTML Template',
        PlainTextTemplate: 'Plain Text Template',
        Category: 'Category',
        Identifier: 'Identifier',
        Location: 'Location',
        EntryPoint: 'Entry Point',
        Icon: 'Icon',
        LastUpdateTime: 'Last Update Time',
        Host: 'Host',
        Options: 'Options',
        Version: 'Version',
        Json: 'Json',
        PropagationPending: 'Propagation Pending',
        DatasourceManaged: 'Datasource Managed',
        ConnectionId: 'ConnectionId',
        IndexingConnection: 'Indexing Connection',
        PullConnection: 'Pull Connection',
        MaxConcurrentRequests: 'Max Concurrent Requests',
        MaxResultsPerRequests: 'Max Results Per Requests',
        Transforms: 'Transforms',
        Type: 'Type',
        IndexingFrequency: 'Indexing Frequency',
        NextScheduledIndexAt: 'Next Scheduled Index At',
        DisabledAt: 'Disabled At',
        DisabledBy: 'Disabled By',
        IndexingOnStartupAndConfigChange: 'Indexing On Startup And Config Change',
        ColumnDefinitions: 'Column Definitions',
        BatchAction: 'Batch Action',
        IsContextCondition: 'Is Context Condition',
        VersionedItemType: 'Versioned Item Type',
        Versions: 'Versions',
    },
} as const;


